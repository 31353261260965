import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import lightBlue from '@material-ui/core/colors/lightBlue';
import deepOrange from '@material-ui/core/colors/deepOrange';
import { SnackbarProvider } from 'notistack'
import CssBaseline from '@material-ui/core/CssBaseline'

import App from './App';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#e20613',
		},
		secondary: lightBlue,
		//type: 'dark',
		error: deepOrange,
	},
	sidebar: {
		width: 240,
	}
})

ReactDOM.render(
	<React.StrictMode>
		
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<SnackbarProvider maxSnack={8} anchorOrigin={{ vertical:'bottom', horizontal:'center' }}>
				<App />
			</SnackbarProvider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
