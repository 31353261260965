import React, { useContext } from 'react'
import clsx from 'clsx'
import { Link, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import APICtx from './lib/api'

const useStyles = makeStyles(theme => ({
	appbarWithSidebar: {
		[theme.breakpoints.up('sm')]: {
			width: `calc(100% - ${theme.sidebar.width}px)`,
			marginLeft: theme.sidebar.width,
		},
	},
	btnSidebarToggle: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	appbarTitle: {
		flexGrow: 1,
	},
	btnTool: {
		color: theme.palette.primary.contrastText,
		textDecoration: 'none',
	},
}))

function Hamburger({ setSidebarActive }) {
	const styles = useStyles()
	return (
		<IconButton
			color="inherit"
			aria-label="apri menu"
			edge="start"
			onClick={() => setSidebarActive(true)}
			className={styles.btnSidebarToggle}
		>
			<MenuIcon />
		</IconButton>
	)
}

function Alarms() {
	const api = useContext(APICtx)
	const styles = useStyles()
	const count = api.countAlarms()

	let icon = <NotificationsIcon className={styles.btnTool} />
	if(count>0)
		icon = <Badge badgeContent={count} color="error">{icon}</Badge>
	
	return (
		<IconButton aria-label="Notifiche" component={Link} to="/dashboard">
			{icon}
		</IconButton>
	)
}

function BtnBack() {
	const history = useHistory()
	const styles = useStyles()

	return (
		<IconButton aria-label="Go back" onClick={() => history.goBack()}>
			<ArrowBackIcon className={styles.btnTool} />
		</IconButton>
	)
}

export default function ToolBar({ title, sidebarEmpty, setSidebarActive }) {
	const styles = useStyles()
	const api = useContext(APICtx)

	return (
		<AppBar position="fixed" className={clsx(!sidebarEmpty && styles.appbarWithSidebar)}>
			<Toolbar>
				{ !sidebarEmpty && <Hamburger setSidebarActive={setSidebarActive} /> }
				<BtnBack />
				<Typography variant="h6" className={styles.appbarTitle} noWrap>
					<Link className={styles.btnTool} to="/">
						{title}
					</Link>
				</Typography>

				{ !api.isAnon && (
					<>
						<Alarms />
						<IconButton aria-label="Profile" component={Link} to="/profile">
							<SettingsIcon className={styles.btnTool} />
						</IconButton>
					</>
				)}
				{ api.isAnon ? (
					<Button color="inherit" component={Link} to="/login">Accedi</Button>
					) : (
					<Button color="inherit" onClick={api.logout}>Logout</Button>
				)}
			</Toolbar>
		</AppBar>
	)
}
