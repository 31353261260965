import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListAltIcon from '@material-ui/icons/ListAlt';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import CreateIcon from '@material-ui/icons/Create';

import DashboardIcon from '@material-ui/icons/Dashboard';
import ContactsIcon from '@material-ui/icons/Contacts';
import ListIcon from '@material-ui/icons/List';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import APICtx from '../lib/api'

const useStyles = makeStyles(theme => ({
	toolbar: theme.mixins.toolbar,
	imgLogo: {
		padding: "0 16px",
		width: "100%",
	},
}))

function MenuItem({ to, icon, label, acls }) {
	const api = useContext(APICtx)
	
	if(acls && !api.chkAcl(acls))
		return null
	else
		return (
			<ListItem button component={Link} to={to}>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText primary={label} />
			</ListItem>
		)
	/*
		<RaisedButton
			label="Sign In"
			containerElement={<Link to="/login" />}
		/>
	return (
		<Link to={to}>
			<ListItem button href={to}>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText primary={label} />
			</ListItem>
		</Link>
	)
	*/
}

export default function MenuStaff() {
	const style = useStyles()

	return (
		<div>
			<div className={style.toolbar}>
				<img src="/logo.svg" className={style.imgLogo} alt="AHT logo" />
			</div>
			<Divider />
			<List>
				<MenuItem to="/dashboard" icon={<DashboardIcon />} label="Bacheca" />
				<Divider />
				<MenuItem to="/addrbook" acls={['addrbook']} icon={<ContactsIcon />} label="Rubrica" />
				<MenuItem to="/quote/new" acls={['quotes']} icon={<CreateIcon />} label="Genera preventivo" />
				<MenuItem to="/quote/list" acls={['quotes']} icon={<ListAltIcon />} label="Lista preventivi" />
				<MenuItem to="/quote/stats" acls={['admin']} icon={<MonetizationOnIcon />} label="Statistiche" />
				<Divider />
				<MenuItem to="/tasks" acls={['tasks']} icon={<ListIcon />} label="Lista task" />
				<MenuItem to="/feedbacks" acls={['feedbacks']} icon={<AssignmentIcon />} label="Feedback" />
				<MenuItem to="/chklayout/list" acls={['chklayouts']} icon={<ViewQuiltIcon />} label="Check Layout" />
				<MenuItem to="/schedule" acls={['events']} icon={<ScheduleIcon />} label="Eventi" />
				<Divider />
				<MenuItem to="/fiera" acls={['fiera']} icon={<SupervisedUserCircleIcon />} label="Contatti fiera" />
				<MenuItem to="/catalog" icon={<MenuBookIcon />} label="Catalogo" />
			</List>
		</div>
	)
}
