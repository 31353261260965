import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';

import APICtx from '../lib/api'
import Window from '../Window'
import BtnBlk from '../BtnBlk'
import Table, { TabSearch, setTabScan } from '../Table'

import IconEdit from '@material-ui/icons/Edit'
import IconDelete from '@material-ui/icons/Delete'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

const useStyles = makeStyles(theme => {
	const palette = theme.palette.type
	return {
		error:		{ color: theme.palette.error[palette] },
		warning:	{ color: theme.palette.warning[palette] },
		success:	{ color: theme.palette.success[palette] },
	}
})

function EventUsers({ users }) {
	return users.map(user => <EventUser key={user.uid} user={user} />)
}

function EventUser({ user }) {
	const styles = useStyles()
	const className = user.attending===null ? styles.warning : ( user.attending ? styles.success : styles.error )
	return <div className={className}>{user.label}</div>
}

export default function Schedule() {
	const api = useContext(APICtx)
	const history = useHistory()
	const [ scan, setScan ] = useState({ data:[], count:0 })
	const [ page, setPage ] = useState(0)
	const [ srcTitle, setSrcTitle ] = useState('')
	const [ srcTime, setSrcTime ] = useState('future')
	const [ edits, setEdits ] = useState(0)
	
	useEffect(() => {
		api.call('event/scan', { page, srcTitle, srcTime }).then(ret => setTabScan(ret, setScan, page, setPage))
		// eslint-disable-next-line
	}, [ srcTitle, srcTime, page, edits ])

	const btnEdit = (id) => {
		history.push('/schedule/event/'+id)
	}
	const btnDel = (id) => {
		api.call('event/del', { id }).then(ret => {
			if(ret)
				setEdits(edits+1)
		})
	}
	const btnAttend = (id, flag) => {
		api.call('event/setAttend', { id, flag }).then(ret => {
			if(ret)
				setEdits(edits+1)
		})
	}
	
	const tabDef = [
		{ key:'_start', content:row => (
			<>
				<div>{row.startDate.split('-').reverse().join('/')} {row.startTime}</div>
				<div>{row.endDate.split('-').reverse().join('/')} {row.endTime}</div>
			</>
		)},
		{ key:'title', label:'Titolo' },
		{ key:'_users', label:'Partecipanti', content:row => <EventUsers users={row.users} /> },
		{ key:'customerLabel', label:'Cliente' },
		{ key:'_accept', label:'Adesione', content:row => {
			const myself = row.users.find(rowUser => rowUser.uid===api.getUid)
			if(myself) {
				const sevYes = (myself.attending===null || !myself.attending) ? 'off' : 'success'
				const sevNo = (myself.attending===null || myself.attending) ? 'off' : 'error'
				return <BtnBlk def={[
					{ icon:<CheckCircleOutlineIcon />, onClick:()=>btnAttend(row.id, true), severity:sevYes },
					{ icon:<NotInterestedIcon />, onClick:()=>btnAttend(row.id, false), severity:sevNo },
				]} />
			}
		}},
		{ key:'_btns', content:row => {
			if(api.isAdmin() || api.getUid===row.owner)
				return <BtnBlk def={[
					{ icon:<IconEdit />, onClick:()=>btnEdit(row.id) },
					{ icon:<IconDelete />, severity:'error', onClick:()=>btnDel(row.id), confirmTxt:'Confermare la cancellazione?' },
				]} />
		}},
	]

	return (
		<Window title="Programmazione" sidebar="staff" fab={{ linkTo:'/schedule/event' }}>
			<TabSearch label="Cerca" value={srcTitle} setValue={setSrcTitle} />
			<TabSearch label="Eventi passati/futuri" value={srcTime} setValue={setSrcTime} options={[
				{ value:'future', label:'Eventi odierni e futuri' },
				{ value:'all', label:'Tutti' },
				{ value:'past', label:'Eventi passati' },
			]} />
			<Table def={tabDef} page={page} setPage={setPage}>{scan}</Table> 
		</Window>
	)
}
