import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core'

import TelegramIcon from '@material-ui/icons/Telegram';

import APICtx from '../lib/api'
import Window from '../Window'

const urlTg = 'https://t.me/AHT_Italy_bot?start='

export default function Profile() {
	const api = useContext(APICtx)
	const [ tgKey, setTgKey ] = useState()

	/*
	const initTelegram = () => {
		api.call('user/chatInit', { protocol:'telegram' }).then(ret => {
			window.open(urlTg+ret.token)
		})
	}
								<Button variant="contained" color="primary" onClick={initTelegram}>
									Collega account
								</Button>
	*/

	useEffect(() => {
		api.call('user/chatInit', { protocol:'telegram' }).then(ret => {
			if(ret && ret.token)
				setTgKey(ret.token)
			//window.open(urlTg+ret.token)
		})
		// eslint-disable-next-line
	}, [])

	return (
		<Window title="Profile" sidebar="staff">
			<Grid item xs={12} sm={6}>
				<Card variant="outlined">
					<CardHeader
						avatar={ <TelegramIcon /> }
						title={<Typography variant="h6">Telegram</Typography>}
					/>
					<CardContent>
						<Grid container spacing={1}>
							{ tgKey && (
								<Button variant="contained" color="primary" component="a" href={urlTg+tgKey} target="_blank">
									Collega account
								</Button>
							)}
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Window>
	)
}
