import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';

const useStyles = makeStyles(theme => ({
	drawerPaper: {
		width: theme.sidebar.width,
	},
}))

export default function SideBar({ children, sidebarActive, setSidebarActive, ...props }) {
	const styles = useStyles()
	
	//const { window } = props;
	//const container = window !== undefined ? () => window().document.body : undefined;
					//container={container}

	return (
		<>
			{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
			<Hidden smUp implementation="css">
				<Drawer
					variant="temporary"
					open={sidebarActive}
					onClose={() => setSidebarActive(false)}
					classes={{
						paper: styles.drawerPaper,
					}}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					{children}
				</Drawer>
			</Hidden>
			<Hidden xsDown implementation="css">
				<Drawer
					classes={{
						paper: styles.drawerPaper,
					}}
					variant="permanent"
					open
				>
					{children}
				</Drawer>
			</Hidden>
		</>
	)
}
