import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useParams, useHistory } from "react-router-dom"
import ContactsIcon from '@material-ui/icons/Contacts';

import IconEdit from '@material-ui/icons/Edit'
import IconDelete from '@material-ui/icons/Delete'
import PageviewIcon from '@material-ui/icons/Pageview'

import APICtx from '../lib/api'
import Window from '../Window'
import Followups from '../Followups'
import BtnBlk from '../BtnBlk'
import Form, { FormCard, Input, InputPicker, Select } from '../Form'
import Table from '../Table'
import Jumps from '../Jumps'
import Attachments from '../Attachments'

const useStyles = makeStyles(theme => ({
	formMain: {
		marginBottom: '2em',
	},
}))

export const lineTypes = [
	{ value:'muraleTN',		label:'Murali TN' },
	{ value:'retrobanco',	label:'Retrobanco' },
	{ value:'serviti',		label:'Serviti' },
	{ value:'self',			label:'Self' },
	{ value:'muraleBT',		label:'Murali BT' },
	{ value:'isola',		label:'Isola' },
	{ value:'cella',		label:'Cella' },
	{ value:'plant',		label:'Impianto' },
]

function LayoutList({ id:projectId, readonly }) {
	const [ layouts, setLayouts ] = useState({ data:[], count:0 })
	const [ newInfo, setNewInfo ] = useState({})
	const [ edits, setEdits ] = useState(0)
	const api = useContext(APICtx)
	const classes = useStyles()
	const history = useHistory()

	useEffect(() => {
		api.call('chklayout/lineList', { projectId }).then(ret => {
			setLayouts({ data:ret.list, count:ret.list.length })
		})
		// eslint-disable-next-line
	}, [ projectId, edits ])

	const handleLineEdit = ({ id }) => history.push('/chklayout/edit/'+projectId+'/line/'+id)
	const handleLineDel = ({ id }) => {
		api.call('chklayout/lineDel', { projectId, id }).then(() => setEdits(edits+1))
	}

	const btnDef = readonly ?
		[ { icon:<PageviewIcon />, onClick:handleLineEdit } ] : [
			{ icon:<IconEdit />, onClick:handleLineEdit },
			{ icon:<IconDelete />, onClick:handleLineDel, confirmTxt:'Confermare la cancellazione?' },
		]
	const tabDef = [
		{ key:'name', label:"Nome" },
		{ key:'_type', label:'Tipologia', content:row => lineTypes.filter(lineType => lineType.value===row.type)[0].label },
		{ key:'_btns', content:row => (
			<BtnBlk row={row} def={btnDef} />
		)},
	]

	const handleSubmit = () => {
		api.call('chklayout/lineAdd', { ...newInfo, projectId }).then(ret => {
			if(ret && ret.id) {
				setEdits(+new Date())
				setNewInfo({})
			}
		})
	}

	return (
		<>
			<Typography variant="h5">{ layouts.count>0 ? 'Elenco linee' : 'Nuova linea' }</Typography>
			{ layouts.count>0 && <Table def={tabDef}>{layouts}</Table> }
			{ !readonly && (
				<Form onSubmit={handleSubmit} btnLabel="Aggiungi linea" data={newInfo} setData={setNewInfo} className={classes.formMain}>
					<Input name="name" label="Nome linea" isRequired />
					<Select name="type" label="Tipologia" isRequired options={lineTypes}/>
				</Form>
			)}
		</>
	)
}

export default function ChkLayoutEdit() {
	const api = useContext(APICtx)
	const [ data, setData ] = useState({})
	const { id } = useParams()
	const history = useHistory()
	const classes = useStyles()
	
	const onSubmit = () => {
		api.call('chklayout/set', { id, ...data }).then(ret => {
			if(ret && ret.id && !id)
				history.replace('/chklayout/edit/'+ret.id)
		})
	}

	useEffect(() => {
		if(id)
			api.call('chklayout/get', { id }).then(ret => setData(ret.data))
		// eslint-disable-next-line
	}, [])

	// const customerPickerExtras = [
	// 	{ label:'Crea nuovo', linkTo:'/addrbook/card' }
	// ]

	const readonly = id && !api.isAdmin() && api.getUid!==parseInt(data?.user?.id)

	return (
		<Window title="Check layout" sidebar="staff" >
			<Form onSubmit={onSubmit} btnLabel={ id ? 'Aggiorna informazioni' : 'Crea' } data={data} setData={setData} className={classes.formMain} isDisabled={readonly}>
				<FormCard xs={12}>
					{/* <InputPicker scanCall="addrbook/picker" name="customer" label="Company/Customer" icon={<ContactsIcon />} isRequired btnExtras={customerPickerExtras} /> */}
					<Input name="customerName" label="Nome cliente" isRequired isDisabled={readonly} />
					<Input name="customerVat" label="Partita IVA" isDisabled={readonly} />
					<Input name="name" label="Nome progetto" isDisabled={readonly} />
				</FormCard>
			</Form>
			{ id && <LayoutList id={id} readonly={readonly} /> }
			{ id && <Jumps toTask /> }
			{ id && <Attachments subjType="chklayouts" subjId={id} readOnly={readonly} /> }
			{ id && <Followups type="chklayouts" /> }
		</Window>
	)
}