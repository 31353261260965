import React from "react"
import { useSnackbar } from 'notistack'

const APICtx = React.createContext(null)

export default APICtx

export function apiCall(jwt, tag, p) {
	if(!p)
		p = {}
	const opts = {
		method:		'POST',
		cache:		'no-cache',
		headers:	{
			'Content-Type': 'application/json',
		},
		body:		JSON.stringify({ tag, p }),
	}
	if(jwt)
		opts.headers['Authorization'] = 'Bearer '+jwt
	return fetch('https://servizi.ahtitalia.it/api.php', opts)
		.then(res => res.json())
}

export function useApi(jwt, setJwt, alarms, auth, setAuth) {
	const { enqueueSnackbar } = useSnackbar()

	const api = {
		call: (tag, p) => {
			return apiCall(jwt, tag, p).then(res => {
				if(res.dbgs)
					res.dbgs.forEach(txt => console.log('API-DBG ['+tag+']', txt))
				if(res.errs)
					res.errs.forEach(txt => enqueueSnackbar(txt, { variant:'error' }))
				if(res.oks)
					res.oks.forEach(txt => enqueueSnackbar(txt, { variant:'success' }))
				if(res.infos)
					res.infos.forEach(txt => enqueueSnackbar(txt, { variant:'info' }))
				if(res._stat)
					return res.data ? res.data : {}
			})
		},
		countAlarms: () => {
			return alarms ? alarms.length : 0
		},
		isAnon: !jwt,
		//(!auth || auth.isAnon),
		isAdmin: () => {
			return (auth && auth.isAdmin)
		},
		chkAcl: (aclList) => {
			for(let acl of aclList) {
				if(acl[0]==='!' && auth.acls.includes(acl.substring(1)))
					return false
				else if(acl[0]!=='!' && !auth.acls.includes(acl))
					return false
			}
			return true
		},
		getUid: (auth && auth.uid),
		getJwt: jwt,
		login: (user, pass) => {
			api.call('auth/login', { user, pass }).then(ret => {
				if(ret && ret.jwt) {
					localStorage.setItem('jwt', ret.jwt)
					setJwt(ret.jwt)
				}
			})
		},
		logout: () => {
			setJwt(null)
			setAuth({})
			localStorage.removeItem('jwt')
		},
		print: (subject, p) => api.call('auth/tkPrint', { subject, p }).then(ret => window.open(ret.url, '_blank')),
	}
	return api
}
