import React, { useState, useEffect, useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import { useParams } from "react-router-dom"

import APICtx from '../lib/api'
import Window from '../Window'
import Form, { FormCard, Input, Select, Radios, Checkbox } from '../Form'

import { lineTypes } from './ChkLayoutEdit'


function Opts({ children }) {
	return (
		<FormCard xs={12} sm={6} md={3}>
			{children}
		</FormCard>
	)
}

function SubTemp({ p, data, modes, readonly }) {
	return (
		<Opts>
			{ modes.map(mode => <Checkbox key={mode} name={p+'Temp'+mode} label={mode} isDisabled={readonly} /> )}
		</Opts>
	)
}

function SubDimManual({ p, readonly }) {
	return (
		<Opts>
			<Input name={p+"DimH"} label="Altezza (cm)" isDisabled={readonly} />
			<Input name={p+"DimW"} label="Larghezza (cm)" isDisabled={readonly} />
			<Input name={p+"DimL"} label="Lunghezza (cm)" isDisabled={readonly} />
		</Opts>
	)
}

// function SubDim({ p, hList, pList }) {
// 	const optsH = hList.map(item => {
// 		return { value:String(item), label:'H '+item}
// 	})
// 	const optsP = pList.map(item => {
// 		return { value:String(item), label:'P '+item}
// 	})
// 	return (
// 		<Opts>
// 			<Radios name={p+"DimH"} label="Altezza" isRequired def={optsH} />
// 			<Radios name={p+"DimP"} label="Profondità vasca" isRequired def={optsP} />
// 		</Opts>
// 	)
// }

// function SubDimL({ p, lList }) {
// 	return (
// 		<Opts>
// 			{ lList.map(item => <Input key={item} name={p+"DimL"+item+"Qty"} label={"Nr L "+item} /> )}
// 		</Opts>
// 	)
// }

function SubGas({ p, data, readonly }) {
	return (
		<Opts>
			<Radios name={p+"Gas"} label="Gas" isRequired isDisabled={readonly} def={[
				{ value:"r410a", label:"R 410A" },
				{ value:"r290", label:"R 290" },
				{ value:"r744", label:"R 744" },
				{ value:"other", label:"Altro" },
			]} />
			{ data[p+"Gas"]==='other' && <Input name={p+"GasOther"} label="Altro gas" isDisabled={readonly} /> }
		</Opts>
	)
}

// function SubColor({ p, data }) {
// 	return (
// 		<Opts>
// 			<Radios name={p+"ColorExt"} label="Colore esterno" isRequired def={[
// 				{ value:"acciaio", label:"Acciaio" },
// 				{ value:"colore", label:"Colore" },
// 			]} />
// 			{ data[p+"ColorExt"]==='colore' && <Input name={p+"ColorExtRal"} label="RAL esterno" /> }
// 			<Radios name={p+"ColorInt"} label="Colore interno" isRequired def={[
// 				{ value:"acciaio", label:"Acciaio" },
// 				{ value:"colore", label:"Colore" },
// 			]} />
// 			{ data[p+"ColorInt"]==='colore' && <Input name={p+"ColorIntRal"} label="RAL interno" /> }
// 		</Opts>
// 	)
// }

// function SubSpalle({ p }) {
// 	return (
// 		<Opts>
// 			<Input name={p+"SpallaPanor"} label="Nr spalle panoramiche" />
// 			<Input name={p+"SpallaSpecchio"} label="Nr spalle a specchio" />
// 			<Input name={p+"SpallaCieca"} label="Nr spalle cieche" />
// 		</Opts>
// 	)
// }

// function SubRipiani({ p, nrOnly }) {
// 	return (
// 		<Opts>
// 			<Input name={p+"RipianiQty"} label="Nr ripiani" />
// 			{ !nrOnly && <Checkbox name={p+"RipianiInclinati"} label="Ripiani inclinati?" /> }
// 		</Opts>
// 	)
// }

// function SubPorte({ p, data, isCell }) {
// 	let opts = [
// 		{ value:"scorrevoli", label:"Scorrevoli" },
// 		{ value:"battente", label:"A battente" },
// 	]
// 	if(!isCell)
// 		opts.push({ value:"fullvision", label:"Full vision" })

// 	const showExtras = Boolean(data[p+"Porte"]) && !isCell

// 	return (
// 		<Opts>
// 			<Checkbox name={p+"Porte"} label="Presenza porte?" />
// 			{ Boolean(data[p+"Porte"]) && (
// 				<Radios name={p+"PorteType"} label="Tipologia" isRequired def={opts} />
// 			)}
// 			{ showExtras && <Checkbox name={p+"PorteChAssist"} label="Chiusura assistita?" /> }
// 			{ showExtras && <Checkbox name={p+"PorteVetroAntirifl"} label="Vetro antiriflesso?" /> }
// 		</Opts>
// 	)
// }

// function SubIllum({ p, data }) {
// 	let opts = [
// 		{ value:"cielino", label:"A cielino" },
// 		{ value:"ripiano", label:"A ripiano" },
// 		{ value:"montante", label:"Su montante" },
// 	]
// 	if(data[p+"Porte"])
// 		opts.splice(2, 0, { value:"porte", label:"Su porte" })
// 	return (
// 		<Opts>
// 			<Radios name={p+"Illum"} label="Illuminazione" isRequired def={opts} />
// 		</Opts>
// 	)
// }

// function SubTenda({ p, data }) {
// 	return (
// 		<Opts>
// 			<Checkbox name={p+"Tenda"} label="Presenza tenda notte?" />
// 			{ Boolean(data[p+"Tenda"]) && (
// 				<Radios name={p+"TendaType"} label="Tipologia" isRequired def={[
// 					{ value:"manuale", label:"Manuale" },
// 					{ value:"motorizzata", label:"Motorizzata" },
// 				]} />
// 			)}
// 		</Opts>
// 	)
// }

// function SubBatticarrello({ p, data }) {
// 	return (
// 		<Opts>
// 			<Checkbox name={p+"Batticarrello"} label="Presenza batticarrello?" />
// 			{ Boolean(data[p+"Batticarrello"]) && <Checkbox name={p+"InoxFront"} label="Inox frontale" /> }
// 			{ Boolean(data[p+"Batticarrello"]) && <Checkbox name={p+"InoxLat"} label="Inox laterale" /> }
// 		</Opts>
// 	)
// }

// function SubDivisorio({ p, data }) {
// 	return (
// 		<Opts>
// 			<Input name={p+"DivAltoQty"} label="Nr divisori alti" />
// 			<Input name={p+"DivBassoQty"} label="Nr divisori bassi" />
// 		</Opts>
// 	)
// }

// function SubVetro({ p, data }) {
// 	return (
// 		<Opts>
// 			<Radios name={p+"VetroType"} label="Tipologia vetro" isRequired def={[
// 				{ value:"curvo", label:"Curvo" },
// 				{ value:"squadrato", label:"Squadrato" },
// 			]} />
// 			<Checkbox name={p+"VetroAntirifl"} label="Antiriflesso" />
// 		</Opts>
// 	)
// }

// function BlkLMurale({ p, data }) {
// 	return (
// 		<>
// 			<SubTemp p={p} data={data} modes={[ 'TN', 'BT' ]} />
// 			<Opts>
// 				<Checkbox name={p+"Murale"} label="Murale" />
// 				<Checkbox name={p+"Semivert"} label="Semiverticale" />
// 				<Checkbox name={p+"Svc"} label="Servizio" />
// 				<Checkbox name={p+"Self"} label="Self" />
// 				<Checkbox name={p+"VSingola"} label="Vasca singola" />
// 				<Checkbox name={p+"VIsola"} label="Vasca isola" />
// 			</Opts>
// 			<SubDim p={p}
// 				hList={[ 220, 200, 160, 140, 120 ]}
// 				pList={[ 50, 60, 70, 80, 90 ]}
// 			/>
// 			<SubDimL p={p}
// 				lList={[ 125, 187, 250, 375 ]}
// 			/>
// 			<Opts>
// 				<Checkbox name={p+"Remoto"} label="Remoto" />
// 				<Checkbox name={p+"Plugin"} label="Plug-in" />
// 				<Checkbox name={p+"Spi"} label="SPI" />
// 				<Checkbox name={p+"ValvElettr"} label="Valvole Elettr." />
// 				<Checkbox name={p+"ValvMecc"} label="Valvole Mecc." />
// 			</Opts>
// 			<SubGas p={p} data={data} />
// 			<SubSpalle p={p} />
// 			<SubRipiani p={p} />
// 			<SubPorte p={p} data={data} />
// 			<SubIllum p={p} data={data} />
// 			<SubTenda p={p} data={data} />
// 			<Opts>
// 				<Checkbox name={p+"DivFilo"} label="Divisorio filo" />
// 				<Checkbox name={p+"GriglieFilo"} label="Griglie filo" />
// 				<Checkbox name={p+"FrontalinoPlexi"} label="Frontalino plexi" />
// 			</Opts>
// 			<SubBatticarrello p={p} data={data} />
// 			<SubColor p={p} data={data} />
// 		</>
// 	)
// }

// function BlkLSelf({ p, data }) {
// 	return (
// 		<>
// 			<SubTemp p={p} data={data} modes={[ 'TN', 'Caldo', 'Neutro' ]} />
// 			<Opts>
// 				<Checkbox name={p+"Murale"} label="Murale" />
// 				<Checkbox name={p+"Semivert"} label="Semiverticale" />
// 				<Checkbox name={p+"VSingola"} label="Vasca singola" />
// 				<Checkbox name={p+"VIsola"} label="Vasca isola" />
// 			</Opts>
// 			<SubDim p={p} data={data}
// 				hList={[ 220, 200, 150 ]}
// 				pList={[ 50, 60, 70, 90 ]}
// 			/>
// 			<SubDimL p={p}
// 				lList={[ 94, 125, 187, 250, 312, 375 ]}
// 			/>
// 			<Opts>
// 				<Checkbox name={p+"Remoto"} label="Remoto" />
// 				<Checkbox name={p+"Plugin"} label="Plug-in" />
// 				<Checkbox name={p+"Spi"} label="SPI" />
// 				<Checkbox name={p+"ValvElettr"} label="Valvole Elettr." />
// 				<Checkbox name={p+"ValvMecc"} label="Valvole Mecc." />
// 			</Opts>
// 			<SubGas p={p} data={data} />
// 			<SubSpalle p={p} />
// 			<SubRipiani p={p} />
// 			<SubPorte p={p} data={data} />
// 			<SubIllum p={p} data={data} />
// 			<SubTenda p={p} data={data} />
// 			<Opts>
// 				<Checkbox name={p+"DivFilo"} label="Divisorio filo" />
// 				<Checkbox name={p+"GriglieFilo"} label="Griglie filo" />
// 				<Checkbox name={p+"FrontalinoPlexi"} label="Frontalino plexi" />
// 			</Opts>
// 			<SubBatticarrello p={p} data={data} />
// 			<SubColor p={p} data={data} />
// 		</>
// 	)
// }

// function BlkLServito({ p, data }) {
// 	return (
// 		<>
// 			<SubTemp p={p} data={data} modes={[ 'TN', 'Caldo', 'Neutro' ]} />
// 			<Opts>
// 				<Checkbox name={p+"Lineare"} label="Lineare" />
// 				<Checkbox name={p+"AngoloAperto"} label="Angolo aperto" />
// 				<Checkbox name={p+"AngoloChiuso"} label="Angolo chiuso" />
// 			</Opts>
// 			<SubDim p={p} data={data}
// 				hList={[ 150, 110 ]}
// 				pList={[ 83, 91 ]}
// 			/>
// 			<SubDimL p={p}
// 				lList={[ 94, 125, 188, 250, 312, 375 ]}
// 			/>
// 			<Opts>
// 				<Checkbox name={p+"Remoto"} label="Remoto" />
// 				<Checkbox name={p+"Plugin"} label="Plug-in" />
// 				<Checkbox name={p+"Spi"} label="SPI" />
// 				<Checkbox name={p+"ValvElettr"} label="Valvole Elettr." />
// 				<Checkbox name={p+"ValvMecc"} label="Valvole Mecc." />
// 			</Opts>
// 			<SubGas p={p} data={data} />
// 			<SubDivisorio p={p} data={data} />
// 			<SubVetro p={p} data={data} />
// 			<SubBatticarrello p={p} data={data} />
// 			<SubColor p={p} data={data} />
// 		</>
// 	)
// }

// function BlkLSurgelati({ p, data }) {
// 	return (
// 		<>
// 			<SubTemp p={p} data={data} modes={[ 'BT' ]} />
// 			<Opts>
// 				<Checkbox name={p+"Murale"} label="Murale" />
// 				<Checkbox name={p+"VSingola"} label="Vasca singola" />
// 				<Checkbox name={p+"VIsola"} label="Vasca isola" />
// 			</Opts>
// 			<SubDim p={p} data={data}
// 				hList={[ 220, 200 ]}
// 				pList={[ 50, 60, 70, 80 ]}
// 			/>
// 			<SubDimL p={p}
// 				lList={[ 250, 210, 175, 145 ]}
// 			/>
// 			<Opts>
// 				<Input name={p+"NrPorte2"} label="Nr 2 porte" />
// 				<Input name={p+"NrPorte3"} label="Nr 3 porte" />
// 				<Input name={p+"NrPorte4"} label="Nr 4 porte" />
// 				<Input name={p+"NrPorte5"} label="Nr 5 porte" />
// 			</Opts>
// 			<Opts>
// 				<Checkbox name={p+"Remoto"} label="Remoto" />
// 				<Checkbox name={p+"Plugin"} label="Plug-in" />
// 				<Checkbox name={p+"Spi"} label="SPI" />
// 				<Checkbox name={p+"ValvElettr"} label="Valvole Elettr." />
// 				<Checkbox name={p+"ValvMecc"} label="Valvole Mecc." />
// 			</Opts>
// 			<SubGas p={p} data={data} />
// 			<SubSpalle p={p} />
// 			<SubRipiani p={p} nrOnly />
// 			<SubPorte p={p} data={data} />
// 			<SubIllum p={p} data={data} />
// 			<Opts>
// 				<Checkbox name={p+"DivFilo"} label="Divisorio filo" />
// 				<Checkbox name={p+"GriglieFilo"} label="Griglie filo" />
// 				<Checkbox name={p+"FrontalinoPlexi"} label="Frontalino plexi" />
// 			</Opts>
// 			<SubBatticarrello p={p} data={data} />
// 			<SubColor p={p} data={data} />
// 		</>
// 	)
// }

// function BlkLCella({ p, data }) {
// 	return (
// 		<>
// 			<SubTemp p={p} data={data} modes={[ 'TN', 'BT' ]} />
// 			<Opts>
// 				<Radios name={p+"Pavim"} label="Tipologia pavimento" isRequired def={[
// 					{ value:"senza", label:"Senza" },
// 					{ value:"rialzato", label:"Con pav. rialzato" },
// 					{ value:"incassato", label:"Con pav. incassato" },
// 				]} />
// 			</Opts>
// 			<SubDimManual p={p} />
// 			<SubPorte p={p} data={data} isCell />
// 			<Opts>
// 				<Checkbox name={p+"Scaffalatura"} label="Presenza scaffalatura?" />
// 				<Checkbox name={p+"Guidovia"} label="Presenza guidovia?" />
// 			</Opts>
// 		</>
// 	)
// }

// function BlkImpianto({ p, data }) {
// 	return (
// 		<>
// 			<Opts>
// 				<Radios name={p+"Espansione"} label="Tipologia espansione" isRequired def={[
// 					{ value:"diretta", label:"Diretta" },
// 					{ value:"indiretta", label:"Indiretta" },
// 				]} />
// 			</Opts>
// 			<Opts>
// 				<Radios name={p+"RTech"} label="Tecnologia" isRequired def={[
// 					{ value:"co2", label:"CO2" },
// 					{ value:"idronico", label:"Idronico" },
// 					{ value:"gas", label:"Gas" },
// 				]} />
// 				{ data[p+"RTech"]==='gas' && <Input name={p+"RTechGas"} label="Specifica gas" /> }
// 			</Opts>
// 			<Opts>
// 				<Checkbox name={p+"ConvenyPack"} label="Conveny-pack" />
// 				<Checkbox name={p+"ZeasDaikin"} label="Zeas Daikin" />
// 			</Opts>
// 			<Opts>
// 				<Checkbox name={p+"Telegestione"} label="Telegestione" />
// 				<Radios name={p+"CManutenz"} label="Contratto manutenzione" isRequired def={[
// 					{ value:"programmata", label:"Programmata" },
// 					{ value:"chiamata", label:"A chiamata" },
// 				]} />
// 			</Opts>
// 		</>
// 	)
// }

/* NEW */

function Product({ p, data, readonly }) {
	return (
		<Opts>
			<Radios name={p+"Brand"} label="Marca" isRequired isDisabled={readonly} def={[
				{ value:"frost", label:"Frost" },
				{ value:"freor", label:"Freor" },
				{ value:"aht", label:"AHT" },
				{ value:"other", label:"Altro" },
			]} />
			{ data[p+"Brand"]==='other' && <Input name={p+"BrandOther"} label="Altra marca" isDisabled={readonly} /> }
			<Input name={p+"Model"} label="Modello" isRequired isDisabled={readonly} />
		</Opts>
	)
}

function DimLNr({ p, lList, channeled, readonly }) {
	return (
		<Opts>
			{ Boolean(channeled) && <Checkbox name={p+"Canalizzato"} label="Canalizzato" isDisabled={readonly} /> }
			{ lList.map(item => <Input key={item} name={p+"DimL"+item+"Qty"} label={"Nr L "+item} isDisabled={readonly} /> )}
		</Opts>
	)
}

function CornersNr({ p, readonly }) {
	const items = [
		{ label:'Nr AI 90°', name:'AI90' },
		{ label:'Nr AE 90°', name:'AE90' },
		{ label:'Nr AI 45°', name:'AI45' },
		{ label:'Nr AE 45°', name:'AE45' },
	]
	return (
		<Opts>
			{ items.map(item => <Input key={item.name} name={p+"Corners"+item.name+"Qty"} label={item.label} isDisabled={readonly} /> )}
		</Opts>
	)
}

function Illuminazione({ p, data, celino, front, shelf, readonly }) {
	return (
		<Opts>
			<Typography>Illuminazione</Typography>
			{ Boolean(celino) && <Checkbox name={p+"IlluminazioneCelino"} label="A celino" isDisabled={readonly} /> }
			{ Boolean(shelf) && <Checkbox name={p+"IlluminazioneRipiano"} label="A ripiano" isDisabled={readonly} /> }
			{ Boolean(front) && <Checkbox name={p+"IlluminazioneFrontale"} label="Frontale" isDisabled={readonly} /> }
			{ Boolean(data[p+"Porte"]) && <Checkbox name={p+"IlluminazioneMontante"} label="Su montante" isDisabled={readonly} /> }
			<Input name={p+"ColoreLed"} label="Colore LED" isRequired isDisabled={readonly} />
		</Opts>
	)
}

function Controller({ p, data, readonly }) {
	return (
		<Opts>
			<Radios name={p+"Controller"} label="Controllore e valvole" isRequired isDisabled={readonly} def={[
				{ value:"carel", label:"Carel" },
				{ value:"danfoss", label:"Danfoss" },
				{ value:"eliwell", label:"Eliwell" },
			]} />
			<Checkbox name={p+"ValvElettr"} label="Valvole Elettr." isDisabled={readonly} />
			<Checkbox name={p+"ValvMecc"} label="Valvole Mecc." isDisabled={readonly} />
		</Opts>
	)
}

function Spalle({ p, data, readonly }) {
	return (
		<Opts>
			<Typography>Spalle panoramiche</Typography>
			<Input name={p+"SpallaPanorSX"} label="Nr panoramiche SX" isDisabled={readonly} />
			<Input name={p+"SpallaPanorDX"} label="Nr panoramiche DX" isDisabled={readonly} />
			<Typography>Spalle a specchio</Typography>
			<Input name={p+"SpallaSpecchioSX"} label="Nr a specchio SX" isDisabled={readonly} />
			<Input name={p+"SpallaSpecchioDX"} label="Nr a specchio DX" isDisabled={readonly} />
			<Typography>Spalle cieche</Typography>
			<Input name={p+"SpallaCiecaSX"} label="Nr cieche SX" isDisabled={readonly} />
			<Input name={p+"SpallaCiecaDX"} label="Nr cieche DX" isDisabled={readonly} />
		</Opts>
	)
}

function PorteTenda({ p, data, fullVision, optAssist, optGlasAntiRefl, disableDoors, disableCurtains, readonly }) {
	const types = [
		{ value:"scorrevoli", label:"Scorrevoli" },
		{ value:"battente", label:"A battente" },
	]
	if(fullVision)
		types.push(
			{ value:"fullvision", label:"Full vision" },
			{ value:"fullvisionplus", label:"Full vision plus" },
		)
	const showDoors = !disableDoors && (!Boolean(data[p+"Tenda"]) || Boolean(data[p+"Porte"]))
	const showCurtains = !disableCurtains && (Boolean(data[p+"Tenda"]) || !Boolean(data[p+"Porte"]))
	return (
		<Opts>
			{ showDoors && <Checkbox name={p+"Porte"} label="Presenza porte?" isDisabled={readonly} /> }
			{ Boolean(data[p+"Porte"]) && <>
				<Radios name={p+"PorteType"} label="Tipologia" isRequired isDisabled={readonly} def={types} />
				{ Boolean(optAssist) && <Checkbox name={p+"PorteChAssist"} label="Chiusura assistita?" isDisabled={readonly} /> }
				{ Boolean(optGlasAntiRefl) && <Checkbox name={p+"PorteVetroAntirifl"} label="Vetro antiriflesso?" isDisabled={readonly} /> }
			</> }
			{ showCurtains && <Checkbox name={p+"Tenda"} label="Presenza tenda notte?" isDisabled={readonly} /> }
			{ Boolean(data[p+"Tenda"]) && (
				<Radios name={p+"TendaType"} label="Tipologia" isRequired isDisabled={readonly} def={[
					{ value:"manuale", label:"Manuale" },
					{ value:"motorizzata", label:"Motorizzata" },
				]} />
			)}
		</Opts>
	)
}

function Batticarrello({ p, data, readonly }) {
	return (
		<Opts>
			<Typography>Batticarrelli</Typography>
			<Input name={p+"BatticarrelloFront"} label="Nr frontali" isDisabled={readonly} />
			<Input name={p+"BatticarrelloSide"} label="Nr laterali" isDisabled={readonly} />
		</Opts>
	)
}

function Colors({ p, data, coatingExt, steelInt, steelExt, readonly }) {
	return (
		<Opts>
			<Typography>Rivestimento esterno</Typography>
			{ Boolean(steelExt) && <>
				<Radios name={p+"ColorExt"} label="Colore esterno" isRequired isDisabled={readonly} def={[
					{ value:"acciaio", label:"Acciaio" },
					{ value:"colore", label:"Colore" },
				]} />
			</> }
			{ (!Boolean(steelExt) || data[p+"ColorExt"]==='colore') && <Input name={p+"ColorExtRal"} label="RAL" isRequired isDisabled={readonly} /> }
			{ Boolean(coatingExt) && <Input name={p+"ColorExtFinishing"} label="Rifinutura" isDisabled={readonly} /> }

			<Typography>Rivestimento interno</Typography>
			{ Boolean(steelInt) && <>
				<Radios name={p+"ColorInt"} label="Colore interno" isRequired isDisabled={readonly} def={[
					{ value:"acciaio", label:"Acciaio" },
					{ value:"colore", label:"Colore" },
				]} />
			</> }
			{ (!Boolean(steelInt) || data[p+"ColorInt"]==='colore') && <Input name={p+"ColorIntRal"} label="RAL" isRequired isDisabled={readonly} /> }
		</Opts>
	)
}

function Divisori({ p, data, readonly }) {
	return (
		<Opts>
			<Input name={p+"DivAltoQty"} label="Nr divisori alti" isDisabled={readonly} />
			<Input name={p+"DivBassoQty"} label="Nr divisori bassi" isDisabled={readonly} />
			<Checkbox name={p+"DivMiddleShelf"} label="Ripiano intermedio?" isDisabled={readonly} />
			<Checkbox name={p+"DivIlluminatedShelf"} label="Ripiano illuminato?" isDisabled={readonly} />
			<Radios name={p+"DivSteps"} label="Gradini interni vasca" isRequired isDisabled={readonly} def={[
				{ value:"0", label:"nessun gradino" },
				{ value:"1", label:"1 gradino" },
				{ value:"2", label:"2 gradini" },
				{ value:"3", label:"3 gradini" },
			]} />
		</Opts>
	)
}

function Glass({ p, data, readonly }) {
	return (
		<Opts>
			<Radios name={p+"GlassType"} label="Tipologia vetro" isRequired isDisabled={readonly} def={[
				{ value:"curved", label:"Curvo" },
				{ value:"straight", label:"Dritto" },
			]} />
			<Checkbox name={p+"GlassRearSliding"} label="Scorrevole posteriore?" isDisabled={readonly} />
		</Opts>
	)
}

function BlkMuraleTN({ p, data, readonly }) {
	return (
		<>
			<Product p={p} data={data} readonly={readonly} />
			<Opts>
				<Checkbox name={p+"Remoto"} label="Remoto" isDisabled={readonly} />
				<Checkbox name={p+"Plugin"} label="Plug-in" isDisabled={readonly} />
				<Checkbox name={p+"Spi"} label="SPI" isDisabled={readonly} />
			</Opts>
			<Controller p={p} data={data} readonly={readonly} />
			<Opts>
				<Radios name={p+"DimH"} label="Altezza" isRequired isDisabled={readonly} def={[
					{ value:"200", label:"H 200" },
					{ value:"218", label:"H 218 (solo per Vento)" },
					{ value:"220", label:"H 220" },
					{ value:"238", label:"H 238 (solo per Vento)" },
				]} />
			</Opts>
			<Opts>
				<Radios name={p+"DimD"} label="Profindità vasca" isRequired isDisabled={readonly} def={[
					{ value:"33", label:"P 33 (solo per Frost Trol)" },
					{ value:"50", label:"P 50" },
					{ value:"60", label:"P 60" },
					{ value:"70", label:"P 70" },
					{ value:"80", label:"P 80" },
				]} />
			</Opts>
			<DimLNr p={p} channeled readonly={readonly}
				lList={[ 1250, 1875, 2500, 3750 ]}
			/>
			<Opts>
				<Input name={p+"RipianiQty"} label="Configurazione ripiani" isDisabled={readonly} />
				<Checkbox name={p+"RipianiInclinati"} label="Ripiani inclinati? (non per Frost Trol)" isDisabled={readonly} />
			</Opts>
			<SubGas p={p} data={data} readonly={readonly} />
			<Spalle p={p} data={data} readonly={readonly} />
			<PorteTenda p={p} data={data} optAssist optGlasAntiRefl readonly={readonly} />
			<Illuminazione p={p} data={data} celino front readonly={readonly} />
			<Opts>
				<Checkbox name={p+"AccGriglie"} label="Griglie di fondo" isDisabled={readonly} />
				<Checkbox name={p+"AccDivVasca"} label="Divisori vasca" isDisabled={readonly} />
				{ Boolean(data[p+"AccDivVasca"]) && <Input name={p+"AccDivVascaQty"} label="Qtà divisori vasca" isDisabled={readonly} /> }
				<Checkbox name={p+"AccDivRipiano"} label="Divisori ripiano" isDisabled={readonly} />
				{ Boolean(data[p+"AccDivRipiano"]) && <Input name={p+"AccDivRipianoQty"} label="Qtà divisori ripiano" isDisabled={readonly} /> }
				<Checkbox name={p+"AccFrontPlexi"} label="Frontalini plexiglass" isDisabled={readonly} />
				{ Boolean(data[p+"AccFrontPlexi"]) && <Input name={p+"AccFrontPlexiQty"} label="Qtà frontalini plexiglass" isDisabled={readonly} /> }
				<Checkbox name={p+"AccDivPlexi"} label="Divisori plexiglass" isDisabled={readonly} />
				{ Boolean(data[p+"AccDivPlexi"]) && <Input name={p+"AccDivPlexiQty"} label="Qtà divisori plexiglass" isDisabled={readonly} /> }
				<Input name={p+"AccOthers"} label="Accessori non standard" multiline isDisabled={readonly} />
			</Opts>
			<Batticarrello p={p} data={data} readonly={readonly} />
			<Colors p={p} data={data} coatingExt readonly={readonly} />
		</>
	)
}

function BlkRetrobanco({ p, data, readonly }) {
	return (
		<>
			<Product p={p} data={data} readonly={readonly} />
			<Opts>
				<Checkbox name={p+"Remoto"} label="Remoto" isDisabled={readonly} />
				<Checkbox name={p+"Plugin"} label="Plug-in" isDisabled={readonly} />
				<Checkbox name={p+"Spi"} label="SPI" isDisabled={readonly} />
			</Opts>
			<Controller p={p} data={data} readonly={readonly} />
			<DimLNr p={p} channeled readonly={readonly}
				lList={[ 1250, 1875, 2500, 3750 ]}
			/>
			<Opts>
				<Input name={p+"RipianiQty"} label="Configurazione ripiani" isDisabled={readonly} />
				<Checkbox name={p+"RipianiInclinati"} label="Ripiani inclinati? (non per Frost Trol)" isDisabled={readonly} />
			</Opts>
			<SubGas p={p} data={data} readonly={readonly} />
			<Spalle p={p} data={data} readonly={readonly} />
			<PorteTenda p={p} data={data} readonly={readonly} />
			<Batticarrello p={p} data={data} readonly={readonly} />
			<Illuminazione p={p} data={data} shelf readonly={readonly} />
			<Opts>
				<Checkbox name={p+"RiservaRefrig"} label="Riserva refrigerata" isDisabled={readonly} />
				<Checkbox name={p+"AccAppendiSalumi"} label="Barra appendi salumi" isDisabled={readonly} />
				{ Boolean(data[p+"AccAppendiSalumi"]) && <Input name={p+"AccAppendiSalumiRip"} label="Nr Ripiani" isDisabled={readonly} /> }
				{ Boolean(data[p+"AccAppendiSalumi"]) && <Input name={p+"AccAppendiSalumiGanci"} label="Nr Ganci" isDisabled={readonly} /> }
				<Input name={p+"AccOthers"} label="Accessori non standard" multiline isDisabled={readonly} />
			</Opts>
			<Colors p={p} data={data} coatingExt steelInt readonly={readonly} />
		</>
	)
}

function BlkServiti({ p, data, readonly }) {
	return (
		<>
			<Product p={p} data={data} readonly={readonly} />
			<Opts>
				<Checkbox name={p+'TempTN'} label={'TN'} isDisabled={readonly} />
				<Checkbox name={p+'TempCaldo'} label={'Caldo (solo da 1250)'} isDisabled={readonly} />
				{ Boolean(data[p+'TempCaldo']) && <Checkbox name={p+'TempBM'} label={'BM'} isDisabled={readonly} /> }
				{ Boolean(data[p+'TempCaldo']) && <Checkbox name={p+'TempCS'} label={'CS'} isDisabled={readonly} /> }
				<Checkbox name={p+'TempNeutro'} label={'Neutro'} isDisabled={readonly} />
				<Checkbox name={p+'TempTeka'} label={'Teka (solo da 1250)'} isDisabled={readonly} />
			</Opts>
			<Spalle p={p} data={data} readonly={readonly} />
			<Opts>
				<Radios name={p+"DimD"} label="Profindità vasca" isRequired isDisabled={readonly} def={[
					{ value:"78", label:"P 78" },
					{ value:"83", label:"P 83" },
					{ value:"91", label:"P 91" },
				]} />
				{ parseInt(data[p+'DimD'])===78 && <Checkbox name={p+"RiservaRefrig"} label="Riserva refrigerata?" isDisabled={readonly} /> }
			</Opts>
			<DimLNr p={p} readonly={readonly}
				lList={[ 940, 1250, 1880, 2500, 3120, 3750 ]}
			/>
			<CornersNr p={p} readonly={readonly} />
			<Opts>
				<Checkbox name={p+"Remoto"} label="Remoto" isDisabled={readonly} />
				<Checkbox name={p+"Plugin"} label="Plug-in" isDisabled={readonly} />
				<Checkbox name={p+"Spi"} label="SPI" isDisabled={readonly} />
			</Opts>
			<Controller p={p} data={data} readonly={readonly} />
			<SubGas p={p} data={data} readonly={readonly} />
			<Divisori p={p} data={data} readonly={readonly} />
			<Glass p={p} data={data} readonly={readonly} />
			<Batticarrello p={p} data={data} readonly={readonly} />
			<Illuminazione p={p} data={data} front readonly={readonly} />
			<Colors p={p} data={data} coatingExt steelInt readonly={readonly} />
		</>
	)
}

function BlkSelf({ p, data, readonly }) {
	return (
		<>
			<Product p={p} data={data} readonly={readonly} />
			<Opts>
				<Radios name={p+"SubType"} label="Tipologia" isRequired isDisabled={readonly} def={[
					{ value:"tradizionale", label:"Tradizionale" },
					{ value:"semiverticale", label:"Semiverticale" },
				]} />
				{ data[p+'SubType']==='tradizionale' && (
					<Radios name={p+"SubTypeOpt"} label="Opzione" isRequired isDisabled={readonly} def={[
						{ value:"straight", label:"Dritto" },
						{ value:"curved", label:"Curvo" },
					]} />
				)}
				{ data[p+'SubType']==='semiverticale' && (
					<Radios name={p+"SubTypeOpt"} label="Opzione" isRequired isDisabled={readonly} def={[
						{ value:"wide", label:"Celino largo" },
						{ value:"narrow", label:"Celino stretto" },
					]} />
				)}
			</Opts>
			{ data[p+'SubType']==='tradizionale' && (
				<Opts>
					<Radios name={p+"DimD"} label="Profindità vasca" isRequired isDisabled={readonly} def={[
						{ value:"78", label:"P 78" },
						{ value:"83", label:"P 83" },
						{ value:"91", label:"P 91" },
					]} />
					<Checkbox name={p+"Tank"} label="Vasca" isDisabled={readonly} />
					<Checkbox name={p+"1E"} label="1E (+1 ripiano)" isDisabled={readonly} />
					<Checkbox name={p+"2E"} label="2E (+2 ripiani)" isDisabled={readonly} />
				</Opts>
			)}
			{ data[p+'SubType']==='semiverticale' && <>
				<Opts>
					<Radios name={p+"DimH"} label="Altezza" isRequired isDisabled={readonly} def={[
						{ value:"120", label:"H 120 (1 ripiano)" },
						{ value:"140", label:"H 140 (2 ripiani)" },
						{ value:"160", label:"H 160 (3 ripiani)" },
						{ value:"180", label:"H 180 (4 ripiani)" },
					]} />
				</Opts>
				<Opts>
					<Radios name={p+"DimD"} label="Profindità vasca" isRequired isDisabled={readonly} def={[
						{ value:"50", label:"P 50" },
						{ value:"60", label:"P 60" },
						{ value:"70", label:"P 70" },
						{ value:"80", label:"P 80" },
					]} />
				</Opts>
			</> }
			{ data[p+'SubType']==='tradizionale' ? <DimLNr p={p} readonly={readonly}
				lList={[ 940, 1250, 1875, 2500, 3120, 3750 ]}
			/> : <DimLNr p={p} readonly={readonly}
				lList={[ 1250, 1875, 2500, 3750 ]}
			/> }
			<Opts>
				{ data[p+'SubType']==='semiverticale' && <Checkbox name={p+"InsTestata"} label="Inserire testata" isDisabled={readonly} /> }
				<Checkbox name={p+"Remoto"} label="Remoto" isDisabled={readonly} />
				<Checkbox name={p+"Plugin"} label="Plug-in" isDisabled={readonly} />
				<Checkbox name={p+"Spi"} label="SPI" isDisabled={readonly} />
			</Opts>
			<Controller p={p} data={data} readonly={readonly} />
			<SubGas p={p} data={data} readonly={readonly} />
			<Spalle p={p} data={data} readonly={readonly} />
			<PorteTenda p={p} data={data} optAssist optGlasAntiRefl disableDoors={data[p+'SubType']!=='semiverticale'} readonly={readonly} />
			<Illuminazione p={p} data={data} shelf front={data[p+'SubType']==='tradizionale'} readonly={readonly} />
			<Opts>
				<Checkbox name={p+"AccDivVasca"} label="Divisorio vasca" isDisabled={readonly} />
				<Checkbox name={p+"AccDivRipiano"} label="Divisorio ripiano" isDisabled={readonly} />
				<Checkbox name={p+"AccFrontPlexi"} label="Frontalino plexi" isDisabled={readonly} />
			</Opts>
			<Batticarrello p={p} data={data} readonly={readonly} />
			<Colors p={p} data={data} coatingExt steelInt readonly={readonly} />
		</>
	)
}

function BlkMuraleBT({ p, data, readonly }) {
	return (
		<>
			<Product p={p} data={data} readonly={readonly} />
			<Opts>
				<Radios name={p+"SubType"} label="Tipologia" isRequired isDisabled={readonly} def={[
					{ value:"verticale", label:"Verticale" },
					{ value:"pensile", label:"Pensile" },
				]} />
				{ data[p+'SubType']==='verticale' && <Checkbox name={p+"SubTypeOpt"} value="combinato" label="Combinato" isDisabled={readonly} /> }
			</Opts>
			<Opts>
				<Checkbox name={p+"Remoto"} label="Remoto" isDisabled={readonly} />
				<Checkbox name={p+"Plugin"} label="Plug-in" isDisabled={readonly} />
				<Checkbox name={p+"Spi"} label="SPI" isDisabled={readonly} />
			</Opts>
			<Controller p={p} data={data} readonly={readonly} />
			<Opts>
				<Radios name={p+"DimH"} label="Altezza" isRequired isDisabled={readonly} def={[
					{ value:"200", label:"H 200" },
					{ value:"220", label:"H 220" },
				]} />
			</Opts>
			<Opts>
				<Radios name={p+"DimD"} label="Profindità vasca" isRequired isDisabled={readonly} def={[
					{ value:"45", label:"P 45 (solo Kalea)" },
					{ value:"50", label:"P 50" },
					{ value:"60", label:"P 60" },
				]} />
			</Opts>
			{ data[p+'SubType']==='verticale' ? <DimLNr p={p} readonly={readonly}
				lList={[ 1560, 2340, 3120, 3900 ]}
			/> : <DimLNr p={p} readonly={readonly}
				lList={[ 1875, 2500, 3750 ]}
			/> }
			<SubGas p={p} data={data} readonly={readonly} />
			<Spalle p={p} data={data} readonly={readonly} />
			<Illuminazione p={p} data={data} celino front readonly={readonly} />
			<Opts>
				<Checkbox name={p+"AccDivFilo"} label="Divisorio filo" isDisabled={readonly} />
				<Checkbox name={p+"AccGriglie"} label="Griglie di fondo" isDisabled={readonly} />
				<Checkbox name={p+"AccFrontPlexi"} label="Frontalini plexiglass" isDisabled={readonly} />
				{ Boolean(data[p+"AccFrontPlexi"]) && <Input name={p+"AccFrontPlexiQty"} label="Qtà frontalini plexiglass" isDisabled={readonly} /> }
				<Checkbox name={p+"AccWolfMouth"} label="Cesto bocca di lupo" isDisabled={readonly} />
				<Input name={p+"AccOthers"} label="Accessori non standard" multiline isDisabled={readonly} />
			</Opts>
			<Batticarrello p={p} data={data} readonly={readonly} />
			<Colors p={p} data={data} coatingExt readonly={readonly} />
		</>
	)
}

function BlkIsola({ p, data, readonly }) {
	return (
		<>
			<Product p={p} data={data} readonly={readonly} />
			<Opts>
				<Checkbox name={p+'TempTN'} label={'TN'} isDisabled={readonly} />
				<Checkbox name={p+'TempBT'} label={'BT'} isDisabled={readonly} />
				<Checkbox name={p+'TempBTTN'} label={'BT-TN'} isDisabled={readonly} />
			</Opts>
			<Opts>
				<Checkbox name={p+'VascaSingola'} label={'Vasca singola'} isDisabled={readonly} />
				<Checkbox name={p+'VascaIsola'} label={'Vasca isola'} isDisabled={readonly} />
			</Opts>
			<Spalle p={p} data={data} readonly={readonly} />
			<DimLNr p={p} readonly={readonly}
				lList={[ 1250, 1450, 1850, 1875, 2100, 2500, 3750 ]}
			/>
			<Opts>
				<Checkbox name={p+"Remoto"} label="Remoto" isDisabled={readonly} />
				<Checkbox name={p+"Plugin"} label="Plug-in" isDisabled={readonly} />
				<Checkbox name={p+"Spi"} label="SPI" isDisabled={readonly} />
			</Opts>
			<Controller p={p} data={data} readonly={readonly} />
			<SubGas p={p} data={data} readonly={readonly} />
			<Opts>
				<Radios name={p+"Cover"} label="Chiusura" isRequired isDisabled={readonly} def={[
					{ value:"open", label:"Aperto" },
					{ value:"covered", label:"Coperto" },
				]} />
				{ data[p+"Cover"]==='covered' && <Radios name={p+"ClosingMode"} label="Modalità" isRequired isDisabled={readonly} def={[
					{ value:"push", label:"Push" },
					{ value:"slide", label:"Scorrevole" },
				]} /> }
				<Checkbox name={p+"AccLighting"} label="Illuminazione" isDisabled={readonly} />
				{ Boolean(data[p+'AccLighting']) && <>
					<Checkbox name={p+"AccLightingFront"} label="Illuminazione anteriore" isDisabled={readonly} />
					<Checkbox name={p+"AccLightingBack"} label="Illuminazione posteriore" isDisabled={readonly} />
				</> }
				<Checkbox name={p+"AccKit"} label="Kit accessori" isDisabled={readonly} />
				<Input name={p+"AccNotes"} label="Note" multiline isDisabled={readonly} />
			</Opts>
			<Colors p={p} data={data} coatingExt readonly={readonly} />
		</>
	)
}

function BlkCella({ p, data, readonly }) {
	return (
		<>
			<SubTemp p={p} data={data} modes={[ 'TN', 'BT' ]} readonly={readonly} />
			<Opts>
				<Radios name={p+"Pavim"} label="Tipologia pavimento" isRequired isDisabled={readonly} def={[
					{ value:"senza", label:"Senza" },
					{ value:"rialzato", label:"Con pav. rialzato" },
					{ value:"incassato", label:"Con pav. incassato" },
				]} />
			</Opts>
			<SubDimManual p={p} readonly={readonly} />
			<PorteTenda p={p} data={data} disableCurtains readonly={readonly} />
			<Opts>
				<Input name={p+"DimThick"} label="Spessore" isDisabled={readonly} />
				<Checkbox name={p+"Evaporatore"} label="Evaporatore per allaccio in centrale" isDisabled={readonly} />
				<Radios name={p+"CellType"} label="Tipologia" isRequired isDisabled={readonly} def={[
					{ value:"evaporatore", label:"Evaporatore" },
					{ value:"monoblocco", label:"Monoblocco" },
				]} />
				{ data[p+"CellType"]==='monoblocco' && <Radios name={p+"Monoblocco"} label="Monoblocco" isRequired isDisabled={readonly} def={[
					{ value:"soffitto", label:"Soffitto" },
					{ value:"accavallato", label:"Accavallato" },
				]} /> }
			</Opts>
			<Opts>
				<Input name={p+"Merceologia"} label="Merceologia" multiline isDisabled={readonly} />
			</Opts>
			<Opts>
				<Checkbox name={p+"Scaffalatura"} label="Scaffalatura" isDisabled={readonly} />
				{ Boolean(data[p+'Scaffalatura']) && <Input name={p+"ScaffalaturaNotes"} label="Informazioni scaffalatura" multiline isDisabled={readonly} /> }
				<Checkbox name={p+"Guidovia"} label="Guidovia" isDisabled={readonly} />
				{ Boolean(data[p+'Guidovia']) && <Radios name={p+"GuidoviaType"} label="Tipologia guidovia" isRequired isDisabled={readonly} def={[
					{ value:"castelletto", label:"A castelletto" },
					{ value:"autoportante", label:"Autoportante" },
				]} /> }
				<Checkbox name={p+"Ganciera"} label="Ganciera" isDisabled={readonly} />
			</Opts>
			<Controller p={p} data={data} readonly={readonly} />
			<SubGas p={p} data={data} readonly={readonly} />
		</>
	)
}

function BlkPlant({ p, data, readonly }) {
	return (
		<>
			<SubGas p={p} data={data} readonly={readonly} />
			<Opts>
				<Checkbox name={p+"CVP"} label="Conveni-pack" isDisabled={readonly} />
				<Checkbox name={p+"Zeas"} label="Zeas" isDisabled={readonly} />
				<Checkbox name={p+"TewisHubbard"} label="Tewis/Hubbard" isDisabled={readonly} />
			</Opts>
			<Opts>
				<Checkbox name={p+"Telegestione"} label="Telegestione" isDisabled={readonly} />
				<Radios name={p+"CManutenz"} label="Contratto manutenzione" isRequired isDisabled={readonly} def={[
					{ value:"programmata", label:"Programmata" },
					{ value:"chiamata", label:"A chiamata" },
				]} />
			</Opts>
			<Opts>
				<Typography>Cond. progetto estate</Typography>
				<Input name={p+"ProjSummerExt"} label="Esterno" isDisabled={readonly} />
				<Input name={p+"ProjSummerInt"} label="Interno" isDisabled={readonly} />
				<Typography>Cond. progetto inverno</Typography>
				<Input name={p+"ProjWinterExt"} label="Esterno" isDisabled={readonly} />
				<Input name={p+"ProjWinterInt"} label="Interno" isDisabled={readonly} />
			</Opts>
			<Opts>
				<Input name={p+"Placement"} label="Posizione centrali" multiline isDisabled={readonly} />
			</Opts>
			{ Boolean(data[p+'CVP']) && <>
				<Opts>
					<Typography>Carichi clim. ambiente</Typography>
					<Input name={p+"ProjLoadsSummer"} label="Estivo (kW)" isDisabled={readonly} />
					<Input name={p+"ProjLoadsWinter"} label="Invernale (kW)" isDisabled={readonly} />
				</Opts>
				<Opts>
					<Radios name={p+"ProjUnitType"} label="Tipologia unità interne" isRequired isDisabled={readonly} def={[
						{ value:"canAltaPrev", label:"Canalizzabile alta prevalenza" },
						{ value:"canMediaPrev", label:"Canalizzabile media prevalenza" },
						{ value:"cassRF", label:"Cassetta round flow" },
						{ value:"cassetta4vp", label:"Cassetta 4 vie pensile" },
						{ value:"cassetta2v", label:"Cassetta 2 vie" },
						{ value:"corner", label:"Corner" },
						{ value:"pensileSoffitto", label:"Pensile a soffitto" },
						{ value:"pavimentoVista", label:"Pavimento a vista" },
						{ value:"pavimentoIncasso", label:"Pavimento da incasso" },
					]} />
					<Input name={p+"ProjUnitQty"} label="Numero unità interne" isDisabled={readonly} />
				</Opts>
				<Opts>
					<Checkbox name={p+"ProjAirBlades"} label="Lame d'aria" isDisabled={readonly} />
					{ Boolean(data[p+"ProjAirBlades"]) && <>
						<Input name={p+"ProjAirBladesQty"} label="Numero" isDisabled={readonly} />
						<Input name={p+"ProjAirBladesType"} label="Tipologia" isDisabled={readonly} />
						<Input name={p+"ProjAirBladesDoorSize"} label="Dimensioni porta" isDisabled={readonly} />
					</> }
					<Checkbox name={p+"ProjAirRenewal"} label="Rinnovo aria" isDisabled={readonly} />
					{ Boolean(data[p+"ProjAirRenewal"]) && <Input name={p+"ProjAirRenewalCapacity"} label="Portata rinnovo richiesta (m³/h)" isDisabled={readonly} /> }
				</Opts>
			</> }
		</>
	)
}

export default function ChkLayoutLine() {
	const [ data, setData ] = useState({})
	const [ user, setUser ] = useState({})
	const api = useContext(APICtx)
	const { pid:projectId, id } = useParams()

	useEffect(() => {
		api.call('chklayout/lineGet', { projectId, id }).then(ret => {
			if(ret && ret.data)
				setData(ret.data)
		})
		api.call('chklayout/get', { id:projectId }).then(ret => setUser(ret.data.user))
	}, [ projectId, id ])

	const readonly = !api.isAdmin() && api.getUid!==parseInt(user?.id)
	const handleSubmit = () => api.call('chklayout/lineSet', { ...data, projectId, id })
	//const pPrefix = data.type
	const pPrefix = 'v'

	return (
		<Window title="Check layout" sidebar="staff" >
			<Form onSubmit={handleSubmit} btnLabel="Salva" data={data} setData={setData} isDisabled={readonly}>
				<Typography variant="h5">Configura linea: {data.name}</Typography>
				<FormCard xs={12}>
					<Input name="name" label="Nome progetto" isRequired isDisabled={readonly} />
					<Select name="type" label="Tipologia" isRequired options={lineTypes} isDisabled />
				</FormCard>
				{ /*
					{ data.type==='lMurale' && <BlkLMurale p={pPrefix} data={data} /> }
					{ data.type==='lSelf' && <BlkLSelf p={pPrefix} data={data} /> }
					{ data.type==='lServito' && <BlkLServito p={pPrefix} data={data} /> }
					{ data.type==='lSurgelati' && <BlkLSurgelati p={pPrefix} data={data} /> }
					{ data.type==='lCella' && <BlkLCella p={pPrefix} data={data} /> }
					{ data.type==='impianto' && <BlkImpianto p={pPrefix} data={data} /> }
				*/ }

				{ data.type==='muraleTN' && <BlkMuraleTN p={pPrefix} data={data} readonly={readonly} /> }
				{ data.type==='retrobanco' && <BlkRetrobanco p={pPrefix} data={data} readonly={readonly} /> }
				{ data.type==='serviti' && <BlkServiti p={pPrefix} data={data} readonly={readonly} /> }
				{ data.type==='self' && <BlkSelf p={pPrefix} data={data} readonly={readonly} /> }
				{ data.type==='muraleBT' && <BlkMuraleBT p={pPrefix} data={data} readonly={readonly} /> }
				{ data.type==='isola' && <BlkIsola p={pPrefix} data={data} readonly={readonly} /> }
				{ data.type==='cella' && <BlkCella p={pPrefix} data={data} readonly={readonly} /> }
				{ data.type==='plant' && <BlkPlant p={pPrefix} data={data} readonly={readonly} /> }
			</Form>
		</Window>
	)
}