import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from "react-router-dom"
import { Typography } from '@material-ui/core';

import IconEdit from '@material-ui/icons/Edit'
import FindInPageIcon from '@material-ui/icons/FindInPage';
import DeleteIcon from '@material-ui/icons/Delete';

import APICtx from '../lib/api'
import Window from '../Window'
import BtnBlk from '../BtnBlk'
import Table, { TabSearch } from '../TableVirt'

export default function Addrbook() {
	const api = useContext(APICtx)
	const history = useHistory()
	//const [ scan, setScan ] = useState({ data:[] })
	const [ scan2, setScan2 ] = useState([])
	const [ search, setSearch ] = useState('')
	//const [ page, setPage ] = useState(0)
	const [ edits, setEdits ] = useState(0)

	useEffect(() => {
		//api.call('addrbook/scan', { page, search }).then(ret => setTabScan(ret, setScan, page, setPage))
		api.call('addrbook/scanIds', { search }).then(ret => setScan2(ret.ids))
		// eslint-disable-next-line
	}, [ search, edits ])

	const fetchRow = id => api.call('addrbook/get', { id, extrasHide:true }).then(ret => ret.data)

	const btnEdit = (row) => {
		history.push('/addrbook/card/'+row.id)
	}
	const btnDelete = ({ id }) => {
		api.call('addrbook/del', { id }).then(() => setEdits(+new Date()))
	}

	const tabDef = [
		{ key:'_name', content:row => (
			<>
				{row.name}
				{row.cdc && <Typography variant="caption" display="block">{row.cdc}</Typography> }
			</>
		)},
		{ key:'vat', label:'P.IVA' },
		{ key:'email', label:'E-Mail', hide:'smDown' },
		{ key:'phone', label:'Telefono', hide:'smDown' },
		{ key:'userLabel', label:'Operatore', hide:'smDown' },
		{ key:'_btns', content:row => {
			let ro = !api.isAdmin() && api.getUid!==parseInt(row.userId)
			let editIcon = ro ? <FindInPageIcon /> : <IconEdit />
			let btnDef = [{ icon:editIcon, onClick:btnEdit }]
			if(api.isAdmin())
				btnDef.push({ icon:<DeleteIcon />, onClick:btnDelete, confirmTxt:'Sei sicuro di voler eliminare l\'anagrafica?' })
			return <BtnBlk row={row} def={btnDef} />
		}},
	]

	return (
		<Window title="Rubrica" sidebar="staff" fab={{ linkTo:'/addrbook/card' }}>
			<TabSearch label="Cerca" value={search} setValue={setSearch} />
			<Table def={tabDef} ids={scan2} rowHeight={54} fetchRow={fetchRow} />
		</Window>
	)
}