import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useHistory } from "react-router-dom"
import { Button, Grid, Card, CardHeader, CardContent } from '@material-ui/core/'
import ContactsIcon from '@material-ui/icons/Contacts';

import APICtx from '../lib/api'
import Window from '../Window'
import Form, { FormCard, Input, InputPicker, InputDate, Radios, Checkbox } from '../Form'

function Comments({ children }) {
	return (
		<Grid container spacing={1} direction="column">
			{ children.map(item => <Comment key={item.id} {...item} /> )}
		</Grid>
	)
}

function Comment({ userLabel, date, text }) {
	return (
		<Grid item>
			<Card>
				<CardHeader title={userLabel} subheader={date}/>
				<CardContent>{text}</CardContent>
			</Card>
		</Grid>
	)
}

export default function FeedbackEdit() {
	const api = useContext(APICtx)
	const [ data, setData ] = useState({})
	const [ comment, setComment ] = useState({})
	const [ commentHistory, setCommentHistory ] = useState([])
	const { id } = useParams()
	const history = useHistory()
	
	const onSubmit = () => {
		api.call('feedback/set', data).then(ret => {
			if(ret && ret.id && !id)
				history.replace('/feedback/edit/'+ret.id)
		})
	}
	const onSubmitComment = () => {
		api.call('feedback/setComment', { ...comment, customer:id }).then(updComments)
	}

	const updComments = () => {
		setComment({})
		api.call('feedback/getComment', { customer:id }).then(setCommentHistory)
	}

	useEffect(() => {
		if(id) {
			api.call('feedback/get', { customer:id }).then(ret => setData(ret.data))
			updComments()
		}
		// eslint-disable-next-line
	}, [])

	const infoDef = [
		{ value:"y", label:"Yes" },
		{ value:"n", label:"No" },
		{ value:"m", label:"Maybe" },
	]

	const customerPickerExtras = [
		{ label:'Crea nuovo', linkTo:'/addrbook/card' }
	]

	return (
		<Window title="Scheda feedback" sidebar="staff" >
			<Form onSubmit={onSubmit} btnLabel="Salva" data={data} setData={setData}>
				<FormCard xs={12} md={6}>
					<InputPicker scanCall="addrbook/picker" name="customer" label="Company/Customer" icon={<ContactsIcon />} isRequired isDisabled={!!id} btnExtras={customerPickerExtras} />
					<Checkbox name="customerExisting" label="Existing customer" />
					<Radios name="appField" label="Field of Application" isRequired def={[
						{ value:"f-retail", label:"Food Retail" },
						{ value:"l-fprocess", label:"Logistic/Food processing" },
						{ value:"installer", label:"Installatore" },
						{ value:"other", label:"Other" },
					]} />
					<Input name="appNotes" label="Field of application notes" multiline />
					<Radios name="appQty" label="Nr of BZ applications" isRequired def={[
						{ value:"0-9", label:"<10" },
						{ value:"10-99", label:"<100" },
						{ value:"100-", label:">=100" },
					]} />
				</FormCard>
				<FormCard xs={12} md={6}>
					<Input name="techSupport" label="Supporto tecnico" multiline />
					<Input name="installer" label="Installatore" multiline />
				</FormCard>
				<FormCard xs={12} md={6} label="Meeting">
					<InputDate name="meetingDate" label="Meeting date" isRequired />
					<Radios name="meetingForm" label="Form of Meeting" isRequired def={[
						{ value:"telco", label:"TelCo" },
						{ value:"vc", label:"VC" },
						{ value:"vis-a-vis", label:"Vis a Vis" },
					]} />
					<Input name="personName" label="Name of person spoken to" isRequired />
					<Input name="personRole" label="Function of the person spoken to" />
				</FormCard>
				<FormCard xs={12} md={6} label="Feedback to Brands">
					<Radios name="infoZanotti" label="Zanotti products" def={infoDef} isRequired />
					<Input name="infoZanottiNotes" label="Zanotti: models" />
					<Radios name="infoDaikin" label="Daikin refrigeration - AC combination" def={infoDef} isRequired />
					<Input name="infoDaikinNotes" label="Daikin: models" />
					<Radios name="infoFrosttrol" label="Frost-Trol remote cabinets" def={infoDef} isRequired />
					<Radios name="infoJne" label="J&E Hall products" def={infoDef} isRequired />
				</FormCard>
				<FormCard xs={12} md={6} label="Feedback to Products">
					<Radios name="infoPlugin" label="Plugin" def={infoDef} isRequired />
					<Input name="infoPluginNotes" label="Plugin: models" />
					<Radios name="infoSemiplugin" label="Semi-plugin" def={infoDef} isRequired />
					<Radios name="infoGelati" label="Gelati" def={infoDef} isRequired />
					<Radios name="infoFeb" label="Food & Beverages" def={infoDef} isRequired />
					<Radios name="infoCo2" label="CO2 Applications" def={infoDef} isRequired />
					<Input name="infoCo2Notes" label="CO2: models" />
					<Radios name="infoColdroom" label="Coldroom" def={infoDef} isRequired />
					<Input name="infoColdroomNotes" label="Coldroom: models" />
				</FormCard>
				<FormCard xs={12} md={6} label="Feedback to Services">
					<Radios name="infoSpareparts" label="Spare parts" def={infoDef} isRequired />
					<Radios name="infoAftersales" label="Aftersales service" def={infoDef} isRequired />
					<Radios name="infoMonitoring" label="Monitoring" def={infoDef} isRequired />
					<Radios name="infoTurnkey" label="Installazione chiavi in mano" def={infoDef} isRequired />
				</FormCard>
				<FormCard xs={12} md={6} label="Refrigerants used by customer">
					<Checkbox name="gasR290" label="R290" />
					<Checkbox name="gasR744" label="R744" />
					<Input name="gasOthers" label="Other" />
				</FormCard>
				
				<FormCard xs={12} md={6} label="General Outlook on business development">
					<Input name="genoutlookApps" label="Nr of new stores/applications planned" />
					<Input name="genoutlookRefurbs" label="Nr of refurbishments planned" />
					<Checkbox name="genoutlookRollouts" label="Important roll outs planned" />
					<Input name="genoutlookRolloutsNotes" label="Important roll outs planned" multiline isDisabled={!data.genoutlookRollouts} isRequired={data.genoutlookRollouts} />
				</FormCard>
				
				<FormCard xs={12} label="General Feedback">
					<Radios name="genfeedback" label="General Feedback on AHT performance / recommendations / requirements" isRequired def={[
						{ value:"ok", label:"Positive" },
						{ value:"n", label:"Indifferent" },
						{ value:"ko", label:"Negative" },
					]} />
					<Input name="genfeedbackNotes" label="What is the reason?" />
				</FormCard>
			</Form>
			{ id && (
				<>
					<Button variant="contained" color="default" component={Link} to="/task/card">Crea Task</Button>
					<Button variant="contained" color="default" component={Link} to={"/schedule/event?customer="+id}>Crea Evento</Button>
					<Form onSubmit={onSubmitComment} btnLabel="Salva follow-up" data={comment} setData={setComment}>
						<FormCard xs={12}>
							<Input name="txt" label="Follow-up" multiline />
						</FormCard>
					</Form>
				</>
			)}
			{ commentHistory && commentHistory.list && <Comments>{commentHistory.list}</Comments> }
		</Window>
	)
}