import React, { useState, useContext } from 'react';
import { useHistory, useLocation } from "react-router-dom"

import PersonIcon from '@material-ui/icons/Person';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import Window from '../Window'
import APICtx from '../lib/api'
import Form, { FormCard, Input, Password } from '../Form'

export default function Login() {
	const api = useContext(APICtx)
	const [ data, setData ] = useState({})

	const location = useLocation()
	const { referrer } = location.state || { referrer: { pathname:"/" }}
	const history = useHistory()
	if(!api.isAnon)
		history.replace(referrer)
	
	/*
	const [values, setValues] = React.useState({
		user: '',
		password: '',
		showPassword: false,
	});

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	*/

	function onSubmit() {
		api.login(data.username, data.password)
	}
	
	return (
		<Window title="Login">
			<Form onSubmit={onSubmit} btnLabel="Accedi" btnIcon={<ExitToAppIcon />} data={data} setData={setData} isCentered>
				<FormCard xs={12} sm={6} md={4}>
					<Input name="username" label="Nome utente" icon={<PersonIcon />} />
					<Password name="password" label="Password" icon={<VpnKeyIcon />} />
				</FormCard>
			</Form>
		</Window>
	)
}
