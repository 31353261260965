import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography, Box, Grid, GridList, GridListTile, GridListTileBar, ListSubheader } from '@material-ui/core'

import APICtx from '../lib/api'
import Window from '../Window'
import CatalogOpts from '../CatalogOpts'

const useStyles = makeStyles(theme => ({
	titleBar: {
		background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)', 
		fontWeight: 'bold',
	},
	chips: {
		position:	"absolute",
		top:		theme.spacing(1),
		left:		theme.spacing(1),
	},
}))

function useQuery(key) {
	return new URLSearchParams(useLocation().search).get(key)
}

function Categories() {
	const [ cats, setCats ] = useState([])
	
	const classes = useStyles()
	const theme = useTheme()
	const cols = useMediaQuery(theme.breakpoints.up('sm')) ? 2 : 1

	useEffect(() => {
		setCats([
			{ label:"Tradizionale",		tag:"tradizionale" },
			{ label:"Verticale",		tag:"verticale" },
			{ label:"Semi Verticale",	tag:"semi-verticale" },
			{ label:"Orizzontale",		tag:"orizzontale" },
			{ label:"Impianti",		tag:"impianti",		colsMax:true },
		])
	}, [])

	const gridCats = cats.map(cat => {
		const title = <Typography variant="h4">{cat.label}</Typography>
		const linkTo = "/catalog?cat="+cat.tag
		const colsQty = cat.colsMax ? cols : 1
		return (
			<GridListTile key={cat.tag} component={Link} to={linkTo} cols={colsQty} >
				<img src="https://via.placeholder.com/1240x400" alt="Category" />
				<GridListTileBar title={title} className={classes.titleBar} />
			</GridListTile>
		)
	})

	return (
		<GridList cols={cols} cellHeight={400} style={{maxWidth:"1240px"}}>
			<GridListTile key="Subheader" cols={cols} style={{ height: 'auto' }}>
				<ListSubheader component="div">Categorie</ListSubheader>
			</GridListTile>
			{gridCats}
		</GridList>
	)
}

function Search() {
	const api = useContext(APICtx)
	const params = new URLSearchParams(useLocation().search)

	const [ scan, setScan ] = useState({ data:[], count:0 })
	const srcStr = params.get('str')
	const srcCat = params.get('cat')
	const srcTemp = params.get('temp')
	const srcConn = params.get('conn')
	const srcUses = params.get('uses')

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))
	let colsQty = isMobile ? 1 : ( isTablet ? 2 : 4 )
	if(scan && scan.count && scan.count<colsQty)
		colsQty = scan.count
	
	useEffect(() => {
		api.call('catalog/scan', {
			page:	0,
			str:	srcStr,
			cat:	srcCat,
			temp:	srcTemp,
			conn:	srcConn,
			uses:	srcUses,
		}).then(setScan)
	}, [ srcStr, srcCat, srcTemp, srcConn, srcUses ])

	return (
		<GridList cols={colsQty} cellHeight="auto" spacing={10}>
			<GridListTile key="Subheader" cols={colsQty} style={{ height: 'auto' }}>
				<ListSubheader component="div">Prodotti</ListSubheader>
			</GridListTile>
			{ scan.data.map(item => (
				<GridListTile key={item.id} component={Link} to={'/catalog/product/'+item.id} >
					<Product {...item} />
				</GridListTile>
			))}
		</GridList>
	)
}

function Product({ id, name, imgCover, options, descrShort }) {
	const classes = useStyles()

	const title = <Typography variant="h6">{name}</Typography>
	const chips = (options && options.length) ? <CatalogOpts active={options} /> : null
	return (
		<>
			<img src={imgCover} alt="prod" />
			{ chips && <Box className={classes.chips}>{chips}</Box> }
			<GridListTileBar title={title} subtitle={descrShort} />
		</>
	)
}

export default function CatalogHome() {
	const qCat = useQuery('cat')
	const sidebar = qCat ? 'search' : null
	const child = qCat ? <Search /> : <Categories />

	return (
		<Window title="Catalogo" sidebar={sidebar} >
			<Grid item container style={{justifyContent:"center"}}>
				{child}
			</Grid>
		</Window>
	)
}
