import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from "react-router-dom"
import IconEdit from '@material-ui/icons/Edit'

import APICtx from '../lib/api'
import Window from '../Window'
import BtnBlk from '../BtnBlk'
import StatusIcon from '../StatusIcon'
import Table, { TabSearch, setTabScan } from '../Table'

export default function FeedbackList() {
	const api = useContext(APICtx)
	const history = useHistory()
	const [ scan, setScan ] = useState({ data:[], count:0 })
	const [ page, setPage ] = useState(0)
	const [ srcString, setSrcString ] = useState('')
	const [ srcUser, setSrcUser ] = useState('')
	//const [ edits, setEdits ] = useState(0)

	useEffect(() => {
		api.call('feedback/scan', { page, srcString, srcUser }).then(ret => setTabScan(ret, setScan, page, setPage))
		// eslint-disable-next-line
	}, [ srcString, srcUser, page ])

	const btnEdit = (row) => {
		history.push('/feedback/edit/'+row.customer)
	}

	const btnDef = [{ icon:<IconEdit />, onClick:btnEdit }]
	const tabDef = [
		{ key:'_status', content:row => <StatusIcon severity={row.severity} /> },
		{ key:'customerName', label:'Cliente' },
		{ key:'meetingDate', label:'Ultimo meeting', type:'DATE' },
		{ key:'followupLastDate', label:'Ultimo follow-up', type:'DATE' },
		{ key:'_btns', content:row => (
			<BtnBlk row={row} def={btnDef} />
		)},
	]
	if(api.isAdmin)
		tabDef.splice(2, 0, { key:'userLabel', label:'Agente' })

	return (
		<Window title="Gestione feedback" sidebar="staff" fab={{ linkTo:'/feedback/edit' }}>
			<TabSearch label="Cerca per cliente" value={srcString} setValue={setSrcString} />
			{ api.isAdmin() && <TabSearch label="Cerca per agente" value={srcUser} setValue={setSrcUser} /> }
			<Table def={tabDef} page={page} setPage={setPage}>{scan}</Table>
		</Window>
	)
}
