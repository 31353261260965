import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

import { Table as MuiTable, TableBody, TableCell, TableHead, TableRow, TableContainer, TablePagination} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
//import Skeleton from '@material-ui/lab/Skeleton';

import {FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
//import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { it } from "date-fns/locale"

import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(theme => ({
	searchBox: {
		'margin-bottom': theme.spacing(1),
	},
	controlMargin: {
		margin: theme.spacing(1),
	},
	floatFab: {
		position: "absolute", 
		bottom: theme.spacing(3),
		right: theme.spacing(3),
	},
	cellMoney: {
		textAlign: 'right',
	},
}))

function TabSearchText({ label, icon, value, setValue }) {
	const styles = useStyles()
	return (
		<TextField
			fullWidth
			className={styles.searchBox}
			label={label}
			value={value}
			InputProps={{
				endAdornment: <InputAdornment position="end">{icon}</InputAdornment>
			}}
			variant="outlined"
			onChange={e => setValue(e.target.value)}
		/>
	)
}

function TabSearchSelect({ label, icon, value, setValue, options }) {
	return (
		<>
			<InputLabel>{label}</InputLabel>
			<Select value={value} label={label} onChange={e => setValue(e.target.value)}>
				{ options.map(opt => (
					<MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
				))}
			</Select>
		</>
	)
}

export function TabSearchDate({ label, value, setValue }) {
	const styles = useStyles()

	return (
		<FormControl variant="outlined" className={styles.searchBox} fullWidth>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
				<KeyboardDatePicker
					clearable
					value={value} 
					onChange={setValue} 
					inputVariant="outlined" 
					format="dd/MM/yyyy" 
					label={label}
				/>
			</MuiPickersUtilsProvider>
		</FormControl>
	)
}

export function TabSearch({ label, icon, ...props }) {
	const styles = useStyles()

	if(!label)
		label = "Filtro di ricerca"
	if(!icon)
		icon = <SearchIcon />

	let input
	if(props.options)
		input = <TabSearchSelect label={label} icon={icon} {...props} />
	else
		input = <TabSearchText label={label} icon={icon} {...props} />

	return (
		<FormControl variant="outlined" className={styles.searchBox} fullWidth>
			{input}
		</FormControl>
	)
}

export function TabControl({ label, icon, linkTo, onClick }) {
	const styles = useStyles()
	let btnProps = {
		variant:	'contained',
		color:		'primary',
		startIcon:	icon,
		className:	styles.controlMargin,
		onClick,
	}
	if(linkTo) {
		btnProps.component = {Link}
		btnProps.to = linkTo
	}
	return <Button {...btnProps}>{label}</Button>
}

function Cell({ children, hide, type, className }) {
	const classes = useStyles()

	let classNameOverride
	switch(type) {
		case 'MONEY':
			classNameOverride = classes.cellMoney
			children = (children/100).toFixed(2).toString().split('.')
			children = children[0].split("").reverse().join("").match(/.{1,3}/g).join(' ').split("").reverse().join("") + '.' + children[1]
			children += ' €'
			//children = parseFloat(children).toFixed(2).toString()+' €'
			break;
	}
	const cell = <TableCell className={classNameOverride ? classNameOverride : className}>{children}</TableCell>
	if(hide) {
		const hideProps = { [hide]:true }
		return <Hidden {...hideProps}>{cell}</Hidden>
	}
	else
		return cell
}

function Head({ def }) {
	const classes = useStyles()

	const cols = def.map(col => {
		let className
		if(col.type==='MONEY')
			className = classes.cellMoney
		return <Cell key={col.key} hide={col.hide} className={className}>{col.label}</Cell>
	})
	return (
		<TableHead>
			<TableRow>{cols}</TableRow>
		</TableHead>
	)
}

function BodyRow({ def, id, fetchRow, height }) {
	const [ draw, setDraw ] = useState(null)

	useEffect(() => {
		fetchRow(id).then(data => {
			if(data) {
				setDraw(def.map(col => {
					let content = col.content ? col.content(data) : data[col.key]
					if(Array.isArray(content))
						content = content.map((contItem, contIdx) => <div key={contIdx}>{contItem}</div>)
					return content
				}))
			}
		})
	}, [])

	let cols
	if(draw) {
		cols = def.map((col, colIdx) => (
			<Cell key={col.key} hide={col.hide} type={col.type} >
				{draw[colIdx]}
			</Cell>
		))
	}
	else
		cols = <TableCell>&nbsp;</TableCell>
	return <TableRow style={{ height }}>{cols}</TableRow>
}

function Pager({ page, setPage, count, pageSize }) {
	const onChangePage = (e, page) => {
		setPage(page)
	}

	return (
		<TablePagination
			component="div"
			count={count}
			rowsPerPage={pageSize}
			rowsPerPageOptions={[pageSize]}
			onChangePage={onChangePage}
			page={page}
		/>
	)
}

export function setTabScan(ret, setScan, page, setPage) {
	if(ret.count && ret.count>0 && ret.pageSize) {
		const pageMax = Math.ceil(ret.count/ret.pageSize) - 1
		if(page>pageMax)
			setPage(pageMax)
	}
	setScan(ret)
}

export default function Table({ def, ids, fetchRow, rowHeight }) {
	const [ drawQty, setDrawQty ] = useState(5)
	const [ scrollPos, setScrollPos ] = useState(0);
	const scrollMax = window.scrollMaxY || (document.documentElement.scrollHeight - document.documentElement.clientHeight)
	
	const handleScroll = () => {
	    setScrollPos(window.pageYOffset)
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });
		return () => {
			window.removeEventListener('scroll', handleScroll);
		}
	}, [])

	useEffect(() => {
		if(ids.length<drawQty)
			setDrawQty(ids.length)
		else if(scrollMax==0 || scrollPos*100/scrollMax > 85) {
			let qtyMore = Math.min(5, ids.length>drawQty ? ids.length-drawQty : 0)
			setDrawQty(drawQty+qtyMore)
		}
	}, [ ids, drawQty, scrollPos, scrollMax ])

	const idsDraw = ids ? ids.slice(0, drawQty) : []
	return (
		<TableContainer>
			<MuiTable>
				<Head def={def} />
				<TableBody>
					{ idsDraw.length>0 && idsDraw.map(id => <BodyRow key={id} id={id} def={def} fetchRow={fetchRow} height={rowHeight} /> )}
				</TableBody>
			</MuiTable>
		</TableContainer>
	)
}
