import React, { useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom"

import APICtx from '../lib/api'
import Window from '../Window'
import Form, { FormCard, Input } from '../Form'

export default function FieraCard() {	
	const [ data, setData ] = useState({})
	const api = useContext(APICtx)
	const { id } = useParams()
	const history = useHistory()

	const handleRefresh = () => {
		id && api.call('fiera/get', { id, extras:true }).then(ret => setData(ret.data))
	}
	// eslint-disable-next-line
	useEffect(handleRefresh, [])

	const onSubmit = () => {
		api.call('fiera/set', { ...data, id }).then(ret => {
			if(ret && ret.id)
				id ? history.replace('/fiera/card/'+ret.id) : setData({})
		})
	}
	
	const ro = !api.isAdmin() && data.user && api.uid!==data.user.id

	const submitLabel = id ? 'Salva modifiche' : 'Salva e inserisci un altro contatto'
	return (
		<Window title="Scheda contatto" sidebar="staff">
			<Form onSubmit={onSubmit} btnLabel={submitLabel} data={data} setData={setData} isDisabled={ro}>
				<FormCard xs={12}>
					<Input name="name" label="Ragione sociale" isDisabled={ro} isRequired />
					<Input name="addrLoc" label="Località" isDisabled={ro} />
					<Input name="addrZip" label="CAP" isDisabled={ro} />
					<Input name="phone" label="Telefono" isDisabled={ro} />
					<Input name="email" label="E-mail" isDisabled={ro} />
					<Input name="interests" label="Interessato a" isDisabled={ro} multiline />
				</FormCard>
			</Form>
		</Window>
	)
}
