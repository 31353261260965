import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from "react-router-dom"
import { Typography } from '@material-ui/core';

import IconEdit from '@material-ui/icons/Edit'
import FindInPageIcon from '@material-ui/icons/FindInPage';
import DeleteIcon from '@material-ui/icons/Delete';

import APICtx from '../lib/api'
import Window from '../Window'
import BtnBlk from '../BtnBlk'
import Table, { TabSearch } from '../TableVirt'

export default function FieraCards() {
	const api = useContext(APICtx)
	const history = useHistory()
	const [ scan, setScan ] = useState([])
	const [ search, setSearch ] = useState('')
	
	const handleRefresh = () => api.call('fiera/scan', { search }).then(ret => setScan(ret.ids))
	// eslint-disable-next-line
	useEffect(handleRefresh, [ search ])

	const fetchRow = id => api.call('fiera/get', { id }).then(ret => ret.data)

	const handleEdit = ({ id }) => history.push('/fiera/card/'+id)
	const handleDelete = ({ id }) => api.call('fiera/del', { id }).then(handleRefresh)

	const tabDef = [
		{ key:'mid', label:'Num.' },
		{ key:'name', label:'Denominazione' },
		{ key:'addrLoc', label:'Località' },
		{ key:'addrZip', label:'CAP' },
		{ key:'email', label:'E-Mail', hide:'smDown' },
		{ key:'phone', label:'Telefono', hide:'smDown' },
		{ key:'_btns', content:row => {
			let ro = !api.isAdmin() && api.getUid!==parseInt(row.userId)
			let editIcon = ro ? <FindInPageIcon /> : <IconEdit />
			let btnDef = [{ icon:editIcon, onClick:handleEdit }]
			if(api.isAdmin())
				btnDef.push({ icon:<DeleteIcon />, onClick:handleDelete, confirmTxt:'Sei sicuro di voler eliminare il contatto?' })
			return <BtnBlk row={row} def={btnDef} />
		}},
	]

	return (
		<Window title="Contatti fiera" sidebar="staff" fab={{ linkTo:'/fiera/card' }}>
			<TabSearch label="Cerca" value={search} setValue={setSearch} />
			<Table def={tabDef} ids={scan} rowHeight={54} fetchRow={fetchRow} />
		</Window>
	)
}