import React, { useState, useEffect, useContext } from 'react';
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core/'

import APICtx from './lib/api'
import Form, { FormCard, Input } from './Form'

function Comments({ children }) {
	return (
		<Grid container spacing={1} direction="column">
			{ children.map(item => <Comment key={item.id} {...item} /> )}
		</Grid>
	)
}

function Comment({ userLabel, date, text }) {
	return (
		<Grid item>
			<Card>
				<CardHeader title={userLabel} subheader={date}/>
				<CardContent>{text}</CardContent>
			</Card>
		</Grid>
	)
}

export default function Followups({ type, id, title="Follow-ups" }) {
	const [ txtNew, setTxtNew ] = useState({})
	const [ txtList, setTxtList ] = useState([])
	const api = useContext(APICtx)

	const handleRefresh = () => api.call('followups/scan', { type, id }).then(ret => setTxtList(ret.list))

	const handleSubmit = () => {
		api.call('followups/append', { type, id, txt:txtNew.txt }).then(ret => {
			setTxtNew({})
			handleRefresh()
		})
	}

	useEffect(handleRefresh, [])

	return (
		<>
			<Form onSubmit={handleSubmit} btnLabel="Salva follow-up" data={txtNew} setData={setTxtNew}>
				<FormCard label={title} xs={12}>
					<Input name="txt" label={title} multiline />
				</FormCard>
			</Form>
			{ Boolean(txtList?.length) && <Comments>{txtList}</Comments> }
		</>
	)
}