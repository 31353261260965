import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from "react-router-dom"
import IconPerson from '@material-ui/icons/Person'
import { Grid, Card, CardContent, CardHeader } from '@material-ui/core'

import APICtx from '../lib/api'
import Window from '../Window'
import Form, { FormCard, Input, InputPicker, InputDate, Checkbox } from '../Form'
import Attachments from '../Attachments'

function Comments({ children }) {
	return (
		<Grid container spacing={1} direction="column">
			{ children.map(item => <Comment key={item.id} {...item} /> )}
		</Grid>
	)
}

function Comment({ userLabel, date, text }) {
	return (
		<Grid item>
			<Card>
				<CardHeader title={userLabel} subheader={date}/>
				<CardContent>{text}</CardContent>
			</Card>
		</Grid>
	)
}

export default function TaskCard() {
	const api = useContext(APICtx)
	const [ data, setData ] = useState({})
	const [ comment, setComment ] = useState({})
	const [ commentHistory, setCommentHistory ] = useState([])
	const { id } = useParams()
	const history = useHistory()

	const onSubmit = () => {
		api.call('task/set', { id, ...data }).then(ret => {
			if(ret && ret.id && !id)
				history.replace('/task/card/'+ret.id)
		})
	}
	const onSubmitComment = () => {
		api.call('task/setComment', { ...comment, id }).then(updComments)
	}

	const updComments = () => {
		setComment({})
		api.call('task/getComment', { id }).then(setCommentHistory)
	}

	useEffect(() => {
		if(id) {
			api.call('task/get', { id }).then(ret => setData(ret.data))
			updComments()
		}
		// eslint-disable-next-line
	}, [])

	const ro = !api.isAdmin() && data.owner && parseInt(api.getUid)!==parseInt(data.owner.id)

	return (
		<Window title="Task" sidebar="staff">
			<Form onSubmit={onSubmit} btnLabel="Salva" data={data} setData={setData}>
				<FormCard xs={12} label={id ? 'Modifica task' : 'Nuovo task'}>
					<Input name="title" label="Titolo" isRequired isDisabled={ro} />
					<Input name="description" label="Descrizione" multiline isDisabled={ro} />
					<InputDate name="expiry" label="Scadenza" isRequired isDisabled={ro} />
					<InputPicker scanCall="user/picker" name="user" label="Assegnato a" icon={<IconPerson />} isRequired isDisabled={ro} />
					{ !ro && <Checkbox label="Al completamento riassegna all'autore" name="isBoomerang" isDisabled={ro} /> }
					<Checkbox label="Imposta come completato" name="isCompleted" />
				</FormCard>
			</Form>
			{ id && <Attachments subjType="tasks" subjId={id} /> }
			{ id && (
				<>
					<Form onSubmit={onSubmitComment} btnLabel="Aggiungi commento" data={comment} setData={setComment}>
						<FormCard xs={12}>
							<Input name="txt" label="Nuovo commento" multiline />
						</FormCard>
					</Form>
				</>
			)}
			{ commentHistory && commentHistory.list && <Comments>{commentHistory.list}</Comments> }
		</Window>
	)
}
