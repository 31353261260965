import React, { useState, useCallback, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import { Link } from "react-router-dom"
import { useDropzone } from 'react-dropzone'

import { Paper, Grid, Typography, Avatar } from '@material-ui/core/'

import IconDelete from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CircularProgress from '@material-ui/core/CircularProgress';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PublishIcon from '@material-ui/icons/Publish';
import PageviewIcon from '@material-ui/icons/Pageview';

import APICtx from './lib/api'
import BtnBlk from './BtnBlk'

const useStyles = makeStyles(theme => ({
	block: {
		//border: "1px solid black",
		marginBottom: theme.spacing(1),
		padding: theme.spacing(1),
	},
	blockDZ: {
		//width: 300,
		//height: 200,
		marginBottom: theme.spacing(1),
		//"padding": "0 !important",
	},
	attachmentAvatar: {
		marginRight: theme.spacing(1),
	},
	attachmentLabel: {
		flexGrow: 1,
	},
	dzIcon: {
		fontSize: 120,
		margin: "auto",
		color: "inherit",
	},
	dropzone: {
		flex: 1,
		display: "flex",
		"flex-direction": "column",
		//padding: "20px",
		//padding: "20px",
		margin: 0,
		width: "100%",
		height: "100%",
		color: "#888888",
		"align-items": "center",
		"border-width": "2px",
		"border-radius": "2px",
		"border-color": "#aaaaaa",
		"border-style": "dashed",
		"background-color": "#fafafa",
		outline: "none",
		//transition: "border .24s ease-in-out",
		cursor: "pointer",
	},
}))

const filePack = file => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve({
			name: file.name,
			type: file.type,
			size: file.size,
			bin: reader.result,
		})
		reader.onerror = error => reject(error)
	})
}

function Dropzone({ subjType, subjId, onlyImages, handleRefresh }) {
	const [ isUploading, setIsUploading ] = useState(false)
	const api = useContext(APICtx)
	const classes = useStyles()

	const onDrop = useCallback(acceptedFiles => {
		if(acceptedFiles.length) {
			setIsUploading(true)
			onUpload(acceptedFiles).then(() => setIsUploading(false))
		}
	}, [])

	const onUpload = async files => {
		const packed = await Promise.all(files.map(filePack))
		const data = {
			type: subjType,
			id: subjId,
			files: packed,
		}
		await api.call('attachments/upload', data)
		handleRefresh()
	}

	const dzOpts = { onDrop }
	if(onlyImages)
		dzOpts.accept = 'image/*'
	const { getRootProps, getInputProps, isDragActive } = useDropzone(dzOpts)

	let statusIcon = isDragActive ?
		<CheckIcon className={classes.dzIcon} /> :
		( isUploading ? 
			<CircularProgress className={classes.dzIcon} size="120px" />
			:
			( onlyImages ? <AddAPhotoIcon className={classes.dzIcon} /> : <PublishIcon className={classes.dzIcon} /> )
		)

	return (
		<Paper className={classes.blockDZ}>
			<div {...getRootProps({className:classes.dropzone})}>
				<input {...getInputProps()} />
				{statusIcon}
			</div>
		</Paper>
	)
}

function Attachment({ id, subjType, subjId, fName, link, readOnly, handleRefresh }) {
	const api = useContext(APICtx)
	const classes = useStyles()

	const handleView = () => {
		window.open(link, '_blank').focus();
	}
	const handleDel = () => api.call('attachments/del', { fid:id, type:subjType, id:subjId }).then(handleRefresh)

	const btnDef = [{ icon:<PageviewIcon />, onClick:handleView }]
	if(!readOnly)
		btnDef.push({ icon:<IconDelete />, onClick:handleDel, confirmTxt:'Confermare la cancellazione?' })

	return (
		<Grid item>
			<Paper className={classes.block}>
				<Grid container alignItems="center">
					<Grid item>
						<Avatar className={classes.attachmentAvatar}>
							<InsertDriveFileIcon />
						</Avatar>
					</Grid>
					<Grid item className={classes.attachmentLabel}>
						<p>{fName}</p>
					</Grid>
					<Grid item>
						<BtnBlk def={btnDef} />
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	)
}

export default function Attachments({ subjType, subjId, max, onlyImages, readOnly }) {
	const [ files, setFiles ] = useState([])
	const api = useContext(APICtx)

	const handleRefresh = () => api.call('attachments/scan', { type:subjType, id:subjId }).then(ret => setFiles(ret.list))
	useEffect(handleRefresh, [])

	const uploadActive = !readOnly && (!max || files.length<max)
	return (
		<Grid container direction="column">
			<Grid item>
				<Typography variant="h5">Allegati</Typography>
			</Grid>
			{ files.map(file => <Attachment key={file.id} {...file} subjType={subjType} subjId={subjId} readOnly={readOnly} handleRefresh={handleRefresh} /> )}
			{ uploadActive && <Dropzone subjType={subjType} subjId={subjId} onlyImages={onlyImages} handleRefresh={handleRefresh} /> }
		</Grid>
	)
}