import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import HelpIcon from '@material-ui/icons/Help'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import ErrorIcon from '@material-ui/icons/Error'
import CheckIcon from '@material-ui/icons/Check'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';

const useStyles = makeStyles(theme => {
	const palette = theme.palette.type
	return {
		error:		{ color: theme.palette.error[palette] },
		errorAvatar:	{ color: theme.palette.error.contrastText,	backgroundColor: theme.palette.error[palette] },
		warning:	{ color: theme.palette.warning[palette] },
		warningAvatar:	{ color: theme.palette.warning.contrastText,	backgroundColor: theme.palette.warning[palette] },
		success:	{ color: theme.palette.success[palette] },
		successAvatar:	{ color: theme.palette.success.contrastText,	backgroundColor: theme.palette.success[palette] },
		running:	{ color: theme.palette.info[palette] },
		runningAvatar:	{ color: theme.palette.info.contrastText,	backgroundColor: theme.palette.info[palette] },
		waiting:	{ color: theme.palette.info[palette] },
		waitingAvatar:	{ color: theme.palette.info.contrastText,	backgroundColor: theme.palette.info[palette] },
		unknown:	{ color: theme.palette.primary[palette] },
		unknownAvatar:	{ color: theme.palette.primary.contrastText,	backgroundColor: theme.palette.primary[palette] },
	}
})

const severities = {
	unknown:	HelpIcon,
	error:		ReportProblemIcon,
	warning:	ErrorIcon,
	success:	CheckIcon,
	running:	DirectionsRunIcon,
	waiting:	HourglassFullIcon,
}

export default function StatusIcon({ severity, isAvatar }) {
	const styles = useStyles()

	if(!severity || !severities[severity])
		severity = 'unknown'
	const Icon = severities[severity]

	if(isAvatar)
		return (
			<Avatar aria-label="grave" className={styles[severity + 'Avatar']}>
				<Icon />
			</Avatar>
		)
	else
		return (
			<Icon className={styles[severity]} />
		)
}
