import React, { useState, useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import Router from './Router'

/*
import Dashboard from './page/Dashboard'
import TaskList from './page/TaskList'
import TaskCard from './page/TaskCard'
import Addrbook from './page/Addrbook'
import AddrbookCard from './page/AddrbookCard'
import FeedbackList from './page/FeedbackList'
import FeedbackEdit from './page/FeedbackEdit'
import Schedule from './page/Schedule'
import Login from './page/Login'
*/

import APICtx, { useApi } from './lib/api'

export default function App() {
	const [ jwt, setJwt ] = useState(localStorage.getItem('jwt'))
	const [ auth, setAuth ] = useState()
	const [ alarms, setAlarms ] = useState()
	const [ tasks, setTasks ] = useState()
	const [ events, setEvents ] = useState()
	const api = useApi(jwt, setJwt, alarms, auth, setAuth)
	let jwtLast = null

	useEffect(() => {
		let timer
		function authRefresh() {
				api.call('auth/refresh', {}).then(ret => {
					if(ret) {
						if(jwtLast!=jwt) {
							if(ret.jwt) {
								localStorage.setItem('jwt', ret.jwt)
								setJwt(ret.jwt)
							}
						}
						setAuth(ret.auth)
						setAlarms(ret.alarms)
						setTasks(ret.tasks)
						setEvents(ret.events)
					}
					timer = setTimeout(authRefresh, 8000)
					jwtLast = jwt
				})
		}
		authRefresh()
		return () => clearTimeout(timer)
		/*
		const timer = setInterval(authRefresh, 10000)
		return () => clearInterval(timer)
		*/

		// eslint-disable-next-line
	}, [ jwt ])

	if(auth===undefined)
		return (
			<Backdrop open={true}>
				<CircularProgress color="inherit" />
			</Backdrop>
		)
	else
		return (
			<APICtx.Provider value={api}>
				<Router summary={{ alarms, tasks, events }} />
			</APICtx.Provider>
		)
}
