import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation, useHistory } from "react-router-dom"

import IconPerson from '@material-ui/icons/Person'
import ContactsIcon from '@material-ui/icons/Contacts';

import APICtx from '../lib/api'
import Window from '../Window'
import Form, { FormCard, Input, InputPicker, InputDate, InputTime } from '../Form'

export default function ScheduleEvent() {
	const api = useContext(APICtx)
	const [ data, setData ] = useState({})
	const { id } = useParams()
	const history = useHistory()
	const cid = new URLSearchParams(useLocation().search).get('customer')

	const onSubmit = () => {
		api.call('event/set', { ...data, id }).then(ret => {
			if(ret && ret.id && !id)
				history.replace('/schedule/event/'+ret.id)
		})
	}

	useEffect(() => {
		if(id)
			api.call('event/get', { id }).then(ret => setData(ret.data))
		else if(cid)
			api.call('addrbook/get', { mid:cid }).then(ret => {
				setData({
					customer: {
						id:	cid,
						label:	ret.data.name,
					}
				})
			})

		// eslint-disable-next-line
	}, [])

	const onChangeDateStart = (valNew) => {
		if(!isNaN(valNew.getTime()))
			setData({
				...data,
				startDate:valNew,
				endDate:valNew,
			})
	}
	
	return (
		<Window title={id ? 'Modifica evento' : 'Nuovo evento'} sidebar="staff">
			<Form onSubmit={onSubmit} btnLabel="Salva" data={data} setData={setData}>
				<FormCard xs={12} md={6} label="Inizio">
					<InputDate name="startDate" label="Data inizio" isRequired onChange={onChangeDateStart} />
					<InputTime name="startTime" label="Ora inizio" isRequired />
				</FormCard>
				<FormCard xs={12} md={6} label="Fine">
					<InputDate name="endDate" label="Data fine" isRequired />
					<InputTime name="endTime" label="Ora fine" isRequired />
				</FormCard>
				<FormCard xs={12}>
					<Input name="title" label="Titolo" isRequired />
					<Input name="notes" label="Note" multiline />
					<InputPicker scanCall="addrbook/picker" name="customer" label="Cliente" icon={<ContactsIcon />} />
					<InputPicker scanCall="user/picker" name="user" label="Assegnato a" icon={<IconPerson />} isRequired isMulti />
				</FormCard>
			</Form>
		</Window>
	)
}
