import React from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import {Container, Box, List, Divider, ListItem, ListItemIcon, ListItemText, Typography, Checkbox, FormControlLabel, TextField} from '@material-ui/core/'

import CatalogOpts from '../CatalogOpts'

const useStyles = makeStyles(theme => ({
	toolbar: theme.mixins.toolbar,
	chips: {
		display: 'flex',
		justifyContent: 'left',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.1),
		},
	},
	fieldMargins: {
		marginTop: theme.spacing(1),
	},
	imgLogo: {
		padding: "10px",
		width: "100%",
	},
}))

function useQuery(key, isMulti) {
	const history = useHistory()
	const params = new URLSearchParams(useLocation().search)
	const sep = '.'

	const q = {
		get: () => {
			const value = params.get(key)
			if(value && isMulti)
				return value.split(sep)
			else if(isMulti)
				return []
			else
				return value
		},
		set: (value) => {
			if(isMulti)
				value = value.join(sep)
			params.set(key, value)
			history.replace('/catalog?'+params.toString())
		},
		toggle: (value, enable) => {
			if(enable)
				q.set(q.get().concat([value]))
			else
				q.set(q.get().filter(item => item!==value ))
		}
	}
	return q
}

function SearchListItem({ to, icon, label, onClick }) {
	if(icon && to) {
		return (
		<ListItem button component={Link} to={to}>
			<ListItemIcon>{icon}</ListItemIcon>
			<ListItemText primary={label} />
		</ListItem>
		)		
	} else if(to){
		return (
		<ListItem button component={Link} to={to}>
			<ListItemText primary={label} />
		</ListItem>
		)
	} else if(icon){
		return (
		<ListItem>
			<ListItemIcon>{icon}</ListItemIcon>
			<ListItemText primary={label} />
		</ListItem>
		)
	} else {
		return(
			<ListItem button onClick={onClick}>
				<ListItemText primary={label} />
			</ListItem>
		)
	}

}

function FieldString({ title, param }) {
	const q = useQuery(param, false)
	const style = useStyles()
	const value = q.get() ? q.get() : ''

	const onChange = (e) => {
		q.set(e.target.value)
	}

	return (
		<TextField
			fullWidth
			label="Cerca"
			className={style.fieldMargins}
			aria-describedby="outlined-search-helper-text"
			inputProps={{ 'aria-label':'Ricerca' }}
			variant="outlined"
			value={value}
			onChange={onChange}
		/>
	)
}

function FieldCats({ title, param, def }) {
	const q = useQuery(param, false)

	const field = def.map(item => {
		const label = q.get()===item.value ? <span style={{fontWeight:'bold'}}>{item.label}</span> : item.label
		return (
			<SearchListItem
				key={item.value}
				onClick={() => q.set(item.value)}
				label={label}
			/>
		)
	})
	return (
		<>
			<Typography variant="overline" component="div">{title}</Typography>
			<List>{field}</List>
		</>
	)
}

function FieldChecks({ title, param, def }) {
	const q = useQuery(param, true)
	const cur = q.get()

	const field = def.map(item => {
		const checked = cur.includes(item.value)
		const ctrl = <Checkbox name="M0" color="secondary" checked={checked} />
		return <FormControlLabel key={item.value} control={ctrl} label={item.label} onChange={() => q.toggle(item.value, !checked)} />
	})

	return (
		<>
			<Typography variant="overline" component="div">{title}</Typography>
			{field}
		</>
	)
}

function FieldChips({ title, param, def }) {
	const q = useQuery(param, true)
	const cur = q.get()
	const style = useStyles()

	const onClick = (optTag, isActive) => {
		q.toggle(optTag, isActive)
	}

	return (
		<>
			<Typography variant="overline" component="div">{title}</Typography>
			<Box className={style.chips} mt={1} pb={3}>
				<CatalogOpts active={cur} showAll onClick={onClick} />
			</Box>
		</>
	)
}

const def = {
	cats: [
		{ label:"Tradizionale",		value:"tradizionale" },
		{ label:"Verticale",		value:"verticale" },
		{ label:"Semi Verticale",	value:"semi-verticale" },
		{ label:"Orizzontale",		value:"orizzontale" },
	],
	temperatures: [
		{ label:"M0",	value:"M0" },
		{ label:"M1",	value:"M1" },
		{ label:"M2",	value:"M2" },
		{ label:"M3",	value:"M3" },
		{ label:"L1",	value:"L1" },
		{ label:"L2",	value:"L2" },
		{ label:"S0",	value:"S0" },
	],
	connections: [
		{ label:"Plugin",	value:"plugin" },
		{ label:"Semi-plugin",	value:"semi-plugin" },
		{ label:"Remote",	value:"remote" },
	],
	uses: [
		{ label:"Salumi e latticini",	value:"salumi-latticini" },
		{ label:"Carne",		value:"carne" },
		{ label:"Pesce",		value:"pesce" },
		{ label:"Frutta e verdura",	value:"frutta-verdura" },
		{ label:"Bevande",		value:"bevande" },
		{ label:"Surgelati",		value:"surgelati" },
		{ label:"Torte e pasticceria",	value:"pasticceria" },
		{ label:"Gelati",		value:"gelati" },
		{ label:"Fiori",		value:"fiori" },
		{ label:"Piatti caldi",		value:"caldi" },
	],
}
const defImpianti = {
	cats: [
		{ label:"Unità moto-condensanti",		value:"motocondensanti" },
		{ label:"Centrali frigo monocompressore",	value:"monocompressore" },
		{ label:"Centrali frigo multicompressore",	value:"multicompressore" },
		{ label:"Sistemi integrati",			value:"integrato" },
		{ label:"Waterloop",				value:"waterloop" },
	],
	gas: [
		{ label:"CO2",	value:"co2" },
		{ label:"HFC",	value:"hfc" },
	],
	chassis: [
		{ label:"Aperte",			value:"open" },
		{ label:"Carenate e silenziate",	value:"closed" },
	],
	temperatures: [
		{ label:"Positiva",	value:"pos" },
		{ label:"Negativa",	value:"neg" },
	],
}

export default function MenuSearch() {
	const q = useQuery('cat', false)
	if(q.get()==='impianti')
		return <MenuSrcImp />
	else
		return <MenuSrcMain />
}

function MenuSrcMain() {
	const style = useStyles()

	return (
		<Container>
			<div className={style.toolbar}>
				<img src="/logo.svg" className={style.imgLogo} alt="AHT logo" />
			</div>
			<Typography variant="h6">Filtri</Typography>
			<Divider />
			<FieldString title="Cerca" param="str" />
			<Divider />
			<FieldCats title="Categorie" param="cat" def={def.cats} />
			<Divider />
			<FieldChecks title="Classe di temperatura" param="temp" def={def.temperatures} />
			<Divider />
			<FieldChecks title="Connessione" param="conn" def={def.connections} />
			<Divider />
			<FieldChips title="Utilizzi" param="uses" def={def.uses} />
		</Container>
	)
}

function MenuSrcImp() {
	const style = useStyles()

	return (
		<Container>
			<div className={style.toolbar}>
				<img src="/logo.svg" className={style.imgLogo} alt="AHT logo" />
			</div>
			<Typography variant="h6">Filtri</Typography>
			<Divider />
			<FieldString title="Cerca" param="str" />
			<Divider />
			<FieldCats title="Categorie" param="imp" def={defImpianti.cats} />
			<Divider />
			<FieldChecks title="Gas" param="gas" def={defImpianti.gas} />
			<Divider />
			<FieldChecks title="Carrozzeria" param="chassis" def={defImpianti.chassis} />
			<Divider />
			<FieldChecks title="Temperatura" param="temp" def={defImpianti.temperatures} />
		</Container>
	)
}
