import React, { useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom"

import PersonIcon from '@material-ui/icons/Person';

import APICtx from '../lib/api'
import Window from '../Window'
import Form, { FormCard, Input, InputPicker } from '../Form'

export default function AddrbookCard() {	
	const [ data, setData ] = useState({})
	const api = useContext(APICtx)
	const { id } = useParams()
	const history = useHistory()

	useEffect(() => {if(id) {
		api.call('addrbook/get', { id }).then(ret => setData(ret.data))
	}
	// eslint-disable-next-line
}, [])

	const onSubmit = () => {
		api.call('addrbook/set', { ...data, id }).then(ret => {
			if(ret && ret.id)
				history.replace('/addrbook/card/'+ret.id)
		})
	}
	
	const ro = !api.isAdmin() && data.user && api.uid!==data.user.id

	return (
		<Window title="Scheda cliente" sidebar="staff">
			<Form onSubmit={onSubmit} btnLabel="Salva" data={data} setData={setData} isDisabled={ro}>
				<FormCard xs={12}>
					<Input name="name" label="Denominazione sociale" isDisabled={ro} />
					<Input name="vat" label="Partita IVA" isDisabled={ro} />
					<Input name="tax_code" label="Codice fiscale" isDisabled={ro} />
					<Input name="cdc" label="Identificativo sede" isDisabled={ro} />
					<Input name="address" label="Indirizzo" isDisabled={ro} />
					<Input name="city" label="Città" isDisabled={ro} />
					<Input name="province" label="Provincia" isDisabled={ro} />
					<Input name="zip" label="CAP" isDisabled={ro} />
					<Input name="country" label="Nazione" isDisabled={ro} />
					<Input name="email" label="E-mail" isDisabled={ro} />
					<Input name="phone" label="Telefono" isDisabled={ro} />
					<Input name="mobile" label="Cellulare" isDisabled={ro} />
					<Input name="sdi" label="SDI" isDisabled={ro} />
					<Input name="pec" label="PEC" isDisabled={ro} />
					<Input name="discount" label="Default sconto" type="number" suffix="%" isDisabled={ro} />
					{ api.isAdmin() && (
						<InputPicker scanCall="user/picker" name="user" label="Operatore" icon={<PersonIcon />} isRequired isDisabled={ro} />
					)}
				</FormCard>
			</Form>
		</Window>
	)
}
