import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from "react-router-dom"
import IconEdit from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import PageviewIcon from '@material-ui/icons/Pageview';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';

import APICtx from '../lib/api'
import Window from '../Window'
import BtnBlk from '../BtnBlk'
//import StatusIcon from '../StatusIcon'
import Table, { TabSearch } from '../TableVirt'

export default function FeedbackList() {
	const api = useContext(APICtx)
	const history = useHistory()
	const [ scan, setScan ] = useState([])
	//const [ page, setPage ] = useState(0)
	const [ srcName, setSrcName ] = useState('')
	const [ srcCustomer, setSrcCustomer ] = useState('')
	const [ srcStatus, setSrcStatus ] = useState('active')

	const refresh = () => api.call('chklayout/scanIds', { srcName, srcCustomer, srcStatus }).then(ret => setScan(ret.ids))

	// eslint-disable-next-line
	useEffect(refresh, [ srcName, srcCustomer, srcStatus ])
	const fetchRow = id => api.call('chklayout/get', { id, extrasHide:true }).then(ret => ret.data)
	
	const handleView = ({ id }) => api.print('chklayout', { id })
	const handleEdit = row => history.push('/chklayout/edit/'+row.id)
	const handleDelete = ({ id }) => api.call('chklayout/del', { id }).then(refresh)
	const handleArchive = ({ id }) => api.call('chklayout/archive', { id }).then(refresh)
	const handleUnarchive = ({ id }) => api.call('chklayout/unarchive', { id }).then(refresh)

	const btnLib = {
		print:		{ icon:<PrintIcon />, onClick:handleView },
		view:		{ icon:<PageviewIcon />, onClick:handleEdit },
		edit:		{ icon:<IconEdit />, onClick:handleEdit },
		del:		{ icon:<DeleteIcon />, confirmTxt:"Sei sicuro di voler eliminare questo elemento?", onClick:handleDelete },
		archive:	{ icon:<ArchiveIcon />, confirmTxt:"Sei sicuro di voler archiviare questo elemento?", onClick:handleArchive },
		unarchive:	{ icon:<UnarchiveIcon />, confirmTxt:"Recuperare dall'archivio questo elemento?", onClick:handleUnarchive },
	}
	const tabDef = [
		{ key:'name', label:'Nome progetto' },
		{ key:'lineQty', label:'# linee' },
		//{ key:'_customerLabel', label:'Cliente', content:row => row.customer.label },
		{ key:'customerName', label:'Cliente' },
		{ key:'_userLabel', label:'Autore', content:row => row.user.label },
		{ key:'_btns', content:row => {
			let btnDef = [ btnLib.print ]
			if(api.isAdmin() || api.getUid===parseInt(row.user.id))
				btnDef.push(btnLib.edit, row.isArchived ? btnLib.unarchive : btnLib.archive, btnLib.del)
			else
				btnDef.push(btnLib.view)
			return <BtnBlk row={row} def={btnDef} />
		}},
	]

	return (
		<Window title="Check layout" sidebar="staff" fab={{ linkTo:'/chklayout/edit' }}>
			<TabSearch label="Cerca per nome progetto" value={srcName} setValue={setSrcName} />
			<TabSearch label="Cerca per cliente" value={srcCustomer} setValue={setSrcCustomer} />
			<TabSearch label="Stato" value={srcStatus} setValue={setSrcStatus} options={[
				{ value:'active', label:'Attivi' },
				{ value:'all', label:'Tutti' },
				{ value:'archived', label:'Archiviati' },
			]} />
			<Table def={tabDef} ids={scan} rowHeight={54} fetchRow={fetchRow} />
		</Window>
	)
}
