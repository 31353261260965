import React, { useContext } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import Dashboard from './page/Dashboard'
import Profile from './page/Profile'
import TaskList from './page/TaskList'
import TaskCard from './page/TaskCard'
import Addrbook from './page/Addrbook'
import AddrbookCard from './page/AddrbookCard'
import QuoteStats from './page/QuoteStats'
import FeedbackList from './page/FeedbackList'
import FeedbackEdit from './page/FeedbackEdit'
import ChkLayoutList from './page/ChkLayoutList'
import ChkLayoutEdit from './page/ChkLayoutEdit'
import ChkLayoutLine from './page/ChkLayoutLine'
import Schedule from './page/Schedule'
import ScheduleEvent from './page/ScheduleEvent'
import FieraCards from './page/FieraCards'
import FieraCard from './page/FieraCard'

import Catalog from './page/Catalog'
import CatalogProduct from './page/CatalogProduct'

import Login from './page/Login'

import APICtx from './lib/api'

const aht1Addr = 'https://preventivi.ahtitalia.it'
//const aht1Addr = 'http://aht1.dev.dlablondon.com'

function RouteUser({ children, location, ...props }) {
	const api = useContext(APICtx)
	if(!api.isAnon)
		return <Route {...props}>{children}</Route>
	else {
		return (
			<Route {...props}>
				<Redirect to={{
					pathname: "/login",
					state: { referrer: location }
				}} />
			</Route>
		)
	}
}

/*
function RouteAdmin({ children, auth, ...props }) {
}
*/

export default function Router({ summary }) {
	const api = useContext(APICtx)
	return (
		<BrowserRouter>
			<Switch>
				<Route path='/login'>
					<Login />
				</Route>

				<RouteUser path='/schedule/event/:id'>
					<ScheduleEvent />
				</RouteUser>
				<RouteUser path='/schedule/event'>
					<ScheduleEvent />
				</RouteUser>
				<RouteUser path='/schedule'>
					<Schedule />
				</RouteUser>

				<RouteUser path='/feedback/edit/:id'>
					<FeedbackEdit />
				</RouteUser>
				<RouteUser path='/feedback/edit'>
					<FeedbackEdit />
				</RouteUser>
				<RouteUser path='/feedbacks'>
					<FeedbackList />
				</RouteUser>
				
				<RouteUser path='/chklayout/edit/:pid/line/:id'>
					<ChkLayoutLine />
				</RouteUser>
				<RouteUser path='/chklayout/edit/:id'>
					<ChkLayoutEdit />
				</RouteUser>
				<RouteUser path='/chklayout/edit'>
					<ChkLayoutEdit />
				</RouteUser>
				<RouteUser path='/chklayout/list'>
					<ChkLayoutList />
				</RouteUser>

				<RouteUser path='/quote/list' component={() => { 
					window.location.href = aht1Addr+'/archive?_tk='+api.getJwt;
					return null;
				}}/>
				<RouteUser path='/quote/stats'>
					<QuoteStats />
				</RouteUser>
				<RouteUser path='/quote/new' component={() => { 
					window.location.href = aht1Addr+'?_tk='+api.getJwt;
					return null;
				}}/>

				<RouteUser path='/addrbook/card/:id'>
					<AddrbookCard />
				</RouteUser>
				<RouteUser path='/addrbook/card'>
					<AddrbookCard />
				</RouteUser>
				<RouteUser path='/addrbook'>
					<Addrbook />
				</RouteUser>
				
				<RouteUser path='/fiera/card/:id'>
					<FieraCard />
				</RouteUser>
				<RouteUser path='/fiera/card'>
					<FieraCard />
				</RouteUser>
				<RouteUser path='/fiera'>
					<FieraCards />
				</RouteUser>

				<RouteUser path='/task/card/:id'>
					<TaskCard />
				</RouteUser>
				<RouteUser path='/task/card'>
					<TaskCard />
				</RouteUser>
				<RouteUser path='/tasks'>
					<TaskList />
				</RouteUser>
				
				<Route path='/catalog/product/:id'>
					<CatalogProduct />
				</Route>
				<Route path='/catalog'>
					<Catalog />
				</Route>

				<RouteUser path='/profile'>
					<Profile />
				</RouteUser>
				<RouteUser path='/dashboard'>
					<Dashboard summary={summary} />
				</RouteUser>
				
				{ api.isAnon ? (
					<Route path='/'>
						<Catalog />
					</Route>
				) : (
					<RouteUser path='/'>
						<Dashboard summary={summary} />
					</RouteUser>
				)}
			</Switch>
		</BrowserRouter>
	)
}
