import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom'
//import { useTheme, makeStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
//import { Paper, Box, Container, Tabs, Tab, Grid, GridList, GridListTile, Card, CardHeader, CardContent, Typography, Button} from '@material-ui/core'
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import useMediaQuery from '@material-ui/core/useMediaQuery';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import APICtx from '../lib/api'
import Window from '../Window'

const useStyles = makeStyles((theme) => ({
		image: {
				maxWidth: "100%",
				height: "auto",
				maxHeight: '600px',
				width:'auto'
			},
		gridList: {
			flexWrap: 'nowrap',
			// Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
			transform: 'translateZ(0)',
			overflowY:"visible",
			overflowX:"auto",
		},
	
// 		gridListV: {
// 			overflowY: "auto",
// 			overflowX:"hidden",
// 			maxHeight:"600px",
// 		},
		galleryItem: {
			cursor: "pointer"
		},
		
		prodImages: {
			maxHeight:"525px",
		},
		infoTab: {
			flexGrow: 1,
		},
		mainGalleryContainer: {
			justifyContent: "center"
		},
		
		prodTitle: {
			fontSize: "3rem"
		},

		boxLinks: {
			marginTop: '24px',
		}
	}))

/*
function galleryHandleClick() {
	console.info('Click!');
}
*/

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
		role="tabpanel"
		hidden={value !== index}
		id={'scrollable-auto-tabpanel-${index}'}
		aria-labelledby={'scrollable-auto-tab-${index}'}
		{...other}
		>
		{value === index && (
			<Box p={3}>
			<Typography>{children}</Typography>
			</Box>
		)}
		</div>
	);
}

function Gallery({ images }) {
	const style = useStyles()
	const [ imgIdx, setImgIdx ] = useState(0)

	useEffect(() => {
		if(images && images.length && imgIdx>=images.length)
			setImgIdx(images.length-1)
	}, [ imgIdx, images ])

	let imgBig = ''
	let imgsMini = null
	if(images && images.length) {
		imgBig = images[imgIdx].big

		imgsMini = images.map((image, imageIdx) => (
			<GridListTile key={imageIdx} onClick={() => setImgIdx(imageIdx)}>
				<img className={clsx(style.image, style.galleryItem)} src={image.mini} alt="" />
			</GridListTile>
		))
	}

	return (
		<>
			<img className={style.image} src={imgBig} alt="" />
			<GridList cols={4.5} spacing={1} className={style.gridList} cellHeight={120}>
				{ imgsMini }
			</GridList>
		</>
	)
}

function Description({ product }) {
	const style = useStyles()
	/*
	const dwls = product.downloads ? product.downloads.map(dwl => (
		<Button variant="contained" color="secondary" startIcon={<PictureAsPdfIcon />}>{dwl}</Button>
	)) : null
	const onClick = () => {
	}
	*/

	return (
		<>
			<Typography variant="h3" >{product.name}</Typography>
			<Typography variant="p">{product.descrLong}</Typography>
				{ product.link && (
					<Box className={style.boxLinks}>
						<Button variant="contained" color="secondary" startIcon={<PictureAsPdfIcon />} component="a" href={product.link} target="_blank">
							Link al sito del produttore
						</Button>
					</Box>
				)}
		</>
	)
}

function Tech({ images }) {
	const style = useStyles()
	return (
		<Grid container xs={12} spacing={1} style={{justifyContent:"space-between"}}>
			{ images.map((image, imageIdx) => (
				<Grid key={imageIdx} item>
					<img className={clsx(style.image, style.galleryItem)} src={image} alt="" />
				</Grid>
			))}
		</Grid>
	)
}

export default function CatalogProduct() {
	const api = useContext(APICtx)
	//const theme = useTheme();
	const classes = useStyles();
	const { id } = useParams()

	const imgsTech = [
		'https://via.placeholder.com/200x150',
		'https://via.placeholder.com/230x270',
		'https://via.placeholder.com/100x250',
		'https://via.placeholder.com/250x100',
		'https://via.placeholder.com/200x150',
	]
	
/*
	const isMdDown = useMediaQuery(theme.breakpoints.down('md'))
 	var colsMain 
 	var rowsMain
 	var colsGallery
 	var rowsGallery
 	var galleryClass
 	if(isMdDown) {
 		colsMain=colsGallery=4
 		rowsMain=3
 		rowsGallery=1
 		galleryClass=classes.gridListH
 	} else {
 		colsMain=3
 		rowsMain=4
 		colsGallery=1
 		rowsGallery=4
 		galleryClass=classes.gridListV
 	}
 	var galleryClass
 	if(isMdDown) {
 		galleryClass=classes.gridListH
 	} else {
 		galleryClass=classes.gridListV
 	}
*/
	const [value, setValue] = React.useState(0);
	const [data, setData] = React.useState({});
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	
	useEffect(() => {
		api.call('catalog/get', { id }).then(ret => {
			if(ret && ret.data)
				setData(ret.data)
		})
		// eslint-disable-next-line
	}, [])

	return (
		<Window title="Prodotto" sidebar="">
			<Container>
				<Gallery images={data.imgs} />

				<Paper className={classes.infoTab}>
					<Tabs
						value={value}
						indicatorColor="primary"
						textColor="primary"
						onChange={handleChange}
						aria-label="Info prodotto"
						centered
					>
						<Tab label="Descrizione" />
						<Tab label="Schede dimensionali" />
					</Tabs>
				</Paper>
				<TabPanel value={value} index={0}>
					<Description product={data} />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<Tech images={imgsTech} />
				</TabPanel>
			</Container>
		</Window>
	)
}
