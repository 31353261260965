import React, { useContext } from 'react';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

import AssignmentIcon from '@material-ui/icons/Assignment';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PersonIcon from '@material-ui/icons/Person';

import APICtx from '../lib/api'
import Window from '../Window'
import StatusIcon from '../StatusIcon'

const useStyles = makeStyles(theme => ({
	personIcon: {
		verticalAlign: 'middle',
	},
}))

function Alarms({ children }) {
	const cards = (children && children.length) ?
		children.map(alarm => <Alarm key={alarm.id} {...alarm} />)
		:
		<Typography paragraph>Non ci sono allarmi al momento.</Typography>
	return (
		<Grid item xs={12} sm={6}>
			<Card variant="outlined">
				<CardHeader
					avatar={ <NotificationsIcon /> }
					title={<Typography variant="h6">Allarmi</Typography>}
				/>
				<Divider />
				<CardContent>
					<Grid container spacing={1}>
						{cards}
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	)
}

function Alarm({ severity, title, description, userLabel, subjType, subjId }) {
	return (
		<DashCard linkType={subjType} linkId={subjId} personLabel={userLabel} severity={severity} title={title}>
			{description}
		</DashCard>
	)
}

function Tasks({ children }) {
	const cards = (children && children.length) ?
		children.map(task => <Task key={task.id} {...task} />)
		:
		<Typography paragraph>Non ci sono task al momento.</Typography>
	
	let action = (
		<IconButton aria-label="settings" component={Link} to="/task/card">
			<AddIcon />
		</IconButton>
	)

	return (
		<Grid item xs={12} sm={6}>
			<Card variant="outlined">
				<CardHeader
					avatar={ <AssignmentIcon /> }
					action={action}
					title={ <Typography variant="h6">Task list</Typography> }
				/>
				<Divider />
				<CardContent>
					<Grid container spacing={1}>
						{cards}
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	)
}

function Task({ id, severity, title, description, ownerLabel, userLabel }) {
	return (
		<DashCard
			linkType="task"
			linkId={id}
			userFrom={ownerLabel}
			userTo={userLabel}
			severity={severity}
			title={title}
		>
			{description}
		</DashCard>
	)
}

function Events({ children }) {
	const cards = (children && children.length) ?
		children.map(task => <Event key={task.id} {...task} />)
		:
		<Typography paragraph>Non ci sono eventi al momento.</Typography>
	
	let action = (
		<IconButton aria-label="settings" component={Link} to="/schedule/event">
			<AddIcon />
		</IconButton>
	)

	return (
		<Grid item xs={12} sm={6}>
			<Card variant="outlined">
				<CardHeader
					avatar={ <ScheduleIcon /> }
					action={action}
					title={ <Typography variant="h6">Event list</Typography> }
				/>
				<Divider />
				<CardContent>
					<Grid container spacing={1}>
						{cards}
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	)
}

function Event({ id, severity, title, ownerLabel, startDate, startTime, customerName }) {
	const titleLabel = startDate+' '+startTime
	const caption = customerName ? 'Customer: '+customerName : ''

	return (
		<DashCard linkType="event" linkId={id} personLabel={"Autore: "+ownerLabel} severity={severity} title={titleLabel} caption={caption}>
			{title}
		</DashCard>
	)
}

function DashCard({ linkType, linkId, children, personLabel, userFrom, userTo, severity, title, caption }) {
	const classes = useStyles()
	const avatar = <StatusIcon severity={severity} isAvatar />
	let linkTo,action
	switch(linkType) {
		case 'task':
			linkTo = '/task/card/' + linkId
			break;
		case 'event':
			linkTo = '/schedule/event/' + linkId
			break;
	}

	let subHeader = []
	if(personLabel)
		subHeader.push((
			<Grid item>
				<PersonIcon className={classes.personIcon} />{personLabel}
			</Grid>
		))
	if(userFrom)
		subHeader.push((
			<Grid item>
				Da: <PersonIcon className={classes.personIcon} />{userFrom}
			</Grid>
		))
	if(userTo)
		subHeader.push((
			<Grid item>
				A: <PersonIcon className={classes.personIcon} />{userTo}
			</Grid>
		))
	const content = (
		<>
			<CardHeader 
				avatar={avatar}
				title={title}
				action={action}
				subheader={<Grid container direction="column" alignItems="flex-start">{subHeader}</Grid>}
			/>
			<CardContent>
				<Typography variant="body2"> 
					{children}
				</Typography>
				{ caption && (
					<Typography variant="caption"> 
						{caption}
					</Typography>
				)}
			</CardContent>
		</>
	)
	return (
		<Grid item xs={12}>
			<Card>
				{ linkTo ?
					<CardActionArea component={Link} to={linkTo}>{content}</CardActionArea>
					:
					<>{content}</>
				}
			</Card>
		</Grid>
	)
}

export default function Dashboard({ summary }) {
	const api = useContext(APICtx)
	//if(acls && !api.chkAcl(acls))
	return (
		<Window title="Dashboard" sidebar="staff">
			<Alarms>{summary.alarms}</Alarms>
			{ api.chkAcl(['tasks']) && <Tasks>{summary.tasks}</Tasks> }
			{ api.chkAcl(['events']) && <Events>{summary.events}</Events> }
		</Window>
	)
}
