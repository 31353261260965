import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import APICtx from '../lib/api'
import Window from '../Window'
import Table, { TabSearchDate } from '../Table'

const useStyles = makeStyles(theme => {
	const palette = theme.palette.type
	return {
		error:		{ color: theme.palette.error[palette] },
		warning:	{ color: theme.palette.warning[palette] },
		success:	{ color: theme.palette.success[palette] },
	}
})

export default function QuoteStats() {
	const [ srcDate1, setSrcDate1 ] = useState(null)
	const [ srcDate2, setSrcDate2 ] = useState(null)
	const [ scan, setScan ] = useState({ data:[], count:0 })
	const api = useContext(APICtx)
	const classes = useStyles()
	/*
	const [ data, setData ] = useState({})
	let inputs = []
	for(let i=1; i<80; i++)
		inputs.push(<Input name={"item-"+i} label={"Item number "+i} />)

	const onSubmit = () => {
	}
	*/

	const tabDef = [
		{ key:'userLabel', label:'Agente' },
		{ key:'tot_w', label:(
			<> <HourglassFullIcon className={classes.warning} /> Valore </>
		), type:'MONEY' },
		{ key:'qty_w', label:(
			<> <HourglassFullIcon className={classes.warning} /> In attesa </>
		) },
		{ key:'tot_a', label:(
			<> <CheckIcon className={classes.success} /> Valore </>
		), type:'MONEY' },
		{ key:'qty_a', label:(
			<> <CheckIcon className={classes.success} /> Acettati </>
		) },
		{ key:'tot_r', label:(
			<> <CloseIcon className={classes.error} /> Valore </>
		), type:'MONEY' },
		{ key:'qty_r', label:(
			<> <CloseIcon className={classes.error} /> Rifiutati </>
		) },
	]

	useEffect(() => {
		const srcFilter = {
			dateFrom:	srcDate1 && !isNaN(srcDate1) ? srcDate1.getTime()/1000 : null,
			dateTo:		srcDate2 && !isNaN(srcDate2) ? srcDate2.getTime()/1000 : null,
		}
		api.call('quote/stats', srcFilter).then(setScan)
		// eslint-disable-next-line
	}, [ srcDate1, srcDate2 ])

	return (
		<Window title="Statistiche preventivi" sidebar="staff">
			<TabSearchDate label="Dal" value={srcDate1} setValue={setSrcDate1} />
			<TabSearchDate label="Al" value={srcDate2} setValue={setSrcDate2} />
			<Table def={tabDef}>{scan}</Table> 
		</Window>
	)
}
