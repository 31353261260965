import React from 'react'
import { Chip } from '@material-ui/core'
//import { useTheme, makeStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faDrumstickBite,
	faFireAlt,
	faWineGlassAlt,
	faCarrot,
	faIceCream,
	faBirthdayCake,
	faFish,
	faCheese,
	faSnowflake,
//	faUtensils,	//cibo pronto?
} from '@fortawesome/free-solid-svg-icons'

import HelpIcon from '@material-ui/icons/Help';
//import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
//import WhatshotIcon from '@material-ui/icons/Whatshot';
import LocalFloristIcon from '@material-ui/icons/LocalFlorist';
//import CakeIcon from '@material-ui/icons/Cake';
//import AcUnitIcon from '@material-ui/icons/AcUnit';

const useStyles = makeStyles(theme => ({
	icon: {
		marginLeft:	theme.spacing(1),
	},
}))

const def = {
	bevande:		{ label:"Bevande", iconFA:faWineGlassAlt },
	caldi:			{ label:"Piatti caldi", iconFA:faFireAlt },
	carne:			{ label:"Carne", iconFA:faDrumstickBite },
	fiori:			{ label:"Fiori", icon:<LocalFloristIcon /> },
	'frutta-verdura':	{ label:"Frutta e verdura", iconFA:faCarrot },
	gelati:			{ label:"Gelati", iconFA:faIceCream },
	pasticceria:		{ label:"Torte e pasticceria", iconFA:faBirthdayCake },
	pesce:			{ label:"Pesce", iconFA:faFish },
	'salumi-latticini':	{ label:"Salumi e latticini", iconFA:faCheese },
	surgelati:		{ label:"Surgelati", iconFA:faSnowflake },
}

function Option({ tag, isActive, onClick }) {
	const styles = useStyles()

	let icon = <HelpIcon />
	if(def.hasOwnProperty(tag)) {
		if(def[tag].icon)
			icon = def[tag].icon
		else if(def[tag].iconFA)
			icon = <FontAwesomeIcon icon={def[tag].iconFA} size="2x" className={styles.icon} />
	}
	const label = def.hasOwnProperty(tag) ? def[tag].label : <HelpIcon />
	const clickable = !!onClick

	const handleClick = () => {
		if(onClick)
			onClick(tag, !isActive)
	}

	return (
		<Chip
			key={tag}
			icon={icon}
			label={label}
			color={ isActive ? 'primary' : undefined }
			clickable={clickable}
			onClick={handleClick}
		/>
	)
}

export default function CatalogOpts({ active, showAll, onClick }) {
	const items = Object.keys(def).map(opt => {
		return (showAll || active.includes(opt)) ? <Option key={opt} tag={opt} isActive={active.includes(opt)} onClick={onClick} /> : null
	})
	return <>{items}</>
}
