import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from "react-router-dom"
import { Typography } from '@material-ui/core';
import IconEdit from '@material-ui/icons/Edit'
import IconDelete from '@material-ui/icons/Delete'

import APICtx from '../lib/api'
import Window from '../Window'
import BtnBlk from '../BtnBlk'
import StatusIcon from '../StatusIcon'
import Table, { TabSearch, setTabScan } from '../Table'

export default function TaskList() {
	const api = useContext(APICtx)
	const history = useHistory()
	const [ scan, setScan ] = useState({ data:[], count:0 })
	const [ page, setPage ] = useState(0)
	const [ sort, setSort ] = useState('+expiry')
	const [ srcString, setSrcString ] = useState('')
	const [ srcStatus, setSrcStatus ] = useState('opened')
	const [ srcUser, setSrcUser ] = useState('')
	const [ edits, setEdits ] = useState(0)
	
	useEffect(() => {
		api.call('task/scan', { page, srcString, srcStatus, srcUser, sort }).then(ret => setTabScan(ret, setScan, page, setPage))
		// eslint-disable-next-line
	}, [ srcString, srcStatus, srcUser, page, edits, sort ])

	const btnEdit = (row) => {
		history.push('/task/card/'+row.id)
	}
	const btnDel = ({ id }) => {
		api.call('task/del', { id }).then(ret => {
			if(ret)
				setEdits(edits+1)
		})
	}

	const btnDef = [{ icon:<IconEdit />, onClick:btnEdit }]
	if(api.isAdmin())
		btnDef.push({ icon:<IconDelete />, onClick:btnDel, confirmTxt:'Confermare la cancellazione?' })

	const tabDef = [
		{ key:'_status', content:row => <StatusIcon severity={row.severity} /> },
		{ key:'created', label:'Creazione', sortable:true, content:row => (
			<>
				<Typography noWrap>{row.created}</Typography>
				<br />
				<Typography noWrap>{row.ownerLabel}</Typography>
			</>
		)},
		{ key:'expiry', label:'Scadenza', sortable:true, content:row => (
			<Typography noWrap>{row.expiry}</Typography>
		)},
		{ key:'title', label:'Descrizione', content:row => (
			<>
				{row.title}
				<br />
				<Typography variant="caption">
					{row.description}
				</Typography>
			</>
		)},
		{ key:'userLabel', label:'Assegnato a' },
		{ key:'_btns', content:row => (
			<BtnBlk row={row} def={btnDef} />
		)},
	]

	return (
		<Window title="Lista tasks" sidebar="staff" fab={{ linkTo:'/task/card' }}>
			<TabSearch label="Cerca per titolo" value={srcString} setValue={setSrcString} />
			{ api.isAdmin() && <TabSearch label="Cerca per utente" value={srcUser} setValue={setSrcUser} /> }
			<TabSearch label="Stato del task" value={srcStatus} setValue={setSrcStatus} options={[
				{ value:'opened', label:'In lavorazione' },
				{ value:'all', label:'Tutti' },
				{ value:'closed', label:'Completati' },
			]} />
			<Table def={tabDef} page={page} setPage={setPage} sort={sort} setSort={setSort}>{scan}</Table>
		</Window>
	)
}
