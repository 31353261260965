import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom"
import { Box, Button } from '@material-ui/core/'

const useStyles = makeStyles(theme => ({
	box: {
		marginBottom: '1em',
	},
}))

export default function Jumps({ toTask, toEvent, customerId, subject }) {
	const classes = useStyles()

	let toParams = []
	customerId && toParams.push('customer='+customerId)
	subject && toParams.push('subj='+subject)

	const taskTo = "/task/card?"+toParams.join('&')
	const eventTo = "/schedule/event?"+toParams.join('&')
	return (
		<Box className={classes.box}>
			{ toTask && <Button variant="contained" color="default" component={Link} to={taskTo}>Crea Task</Button> }
			{ toEvent && customerId && <Button variant="contained" color="default" component={Link} to={eventTo}>Crea Evento</Button> }
		</Box>
	)
}